const initialState = {
  companies: [],
  search_company: [],
  search_table_company: [],
  selected_company: [],
  fetch_schedule: [],
  day_sched_sched: [],
  attn_in_sched: [],
  break_start_sched: [],
  break_sched: [],
  attn_out_sched: [],
  search_sched: [],
  employee_mobile_priv: [],
  search_employee_mobile_priv: [],
  system_loading: false,
};
const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case "companies":
      return {
        ...state,
        companies: action.data,
        search_company: action.data,
        search_table_company: action.data,
      };
    case "searchcompanies":
      let CompanySearch = state.search_company.filter((files) => {
        return (
          files.company_name
            .toLowerCase()
            .indexOf(action.data.toLocaleLowerCase()) !== -1 ||
          files.company_code
            .toLowerCase()
            .indexOf(action.data.toLocaleLowerCase()) !== -1 ||
          files.company_email
            .toLowerCase()
            .indexOf(action.data.toLocaleLowerCase()) !== -1 ||
          files.company_suffix
            .toLowerCase()
            .indexOf(action.data.toLocaleLowerCase()) !== -1
        );
      });
      return {
        ...state,
        search_table_company: CompanySearch,
      };
    case "added_company":
      return {
        ...state,
        companies: state.companies.concat(action.data),
        search_company: state.search_company.concat(action.data),
        search_table_company: state.search_table_company.concat(action.data),
      };
    case "selected_company":
      return {
        ...state,
        selected_company: action.data,
      };
    case "onchange_selected_company":
      return {
        ...state,
        selected_company: {
          ...state.selected_company,
          ...action.data,
        },
      };

    case "update_company":
      state.companies.map((val) => {
        if (val.company_id == action.data.company_id) {
          val.company_name = action.data.company_name;
          val.company_code = action.data.company_code;
          val.company_suffix = action.data.company_suffix;
          val.company_email = action.data.company_email;
        }
      });
      state.search_company.map((val) => {
        if (val.company_id == action.data.company_id) {
          val.company_name = action.data.company_name;
          val.company_code = action.data.company_code;
          val.company_suffix = action.data.company_suffix;
          val.company_email = action.data.company_email;
        }
      });
      return {
        ...state,
      };
    case "delete_company":
      return {
        ...state,
        companies: state.companies.filter(
          (val) => val.company_id != action.data.company_id
        ),
        search_company: state.search_company.filter(
          (val) => val.company_id != action.data.company_id
        ),
        search_table_company: state.search_table_company.filter(
          (val) => val.company_id != action.data.company_id
        ),
      };
    case "fetch_schedule":
      return {
        ...state,
        fetch_schedule: action.data,
        search_sched: action.data,
      };
    case "search_sched":
      let search_sched = state.fetch_schedule.filter((files) => {
        return (
          files.sched_name
            .toLowerCase()
            .indexOf(action.data.toLocaleLowerCase()) !== -1 ||
          files.shift_sched
            .toLowerCase()
            .indexOf(action.data.toLocaleLowerCase()) !== -1 ||
          files.sched_type
            .toLowerCase()
            .indexOf(action.data.toLocaleLowerCase()) !== -1
        );
      });
      return {
        ...state,
        search_sched: search_sched,
      };
    case "day_sched_sched":
      return {
        ...state,
        day_sched_sched: action.data,
      };
    case "attn_in_sched":
      return {
        ...state,
        attn_in_sched: action.data,
      };
    case "break_start_sched":
      return {
        ...state,
        break_start_sched: action.data,
      };
    case "break_sched":
      return {
        ...state,
        break_sched: action.data,
      };
    case "attn_out_sched":
      return {
        ...state,
        attn_out_sched: action.data,
      };
    case "employee_mobile_priv":
      return {
        ...state,
        employee_mobile_priv: action.data,
        search_employee_mobile_priv: action.data,
      };
    case "searching":
      let search_mobile_priv = state.employee_mobile_priv.filter((files) => {
        return (
          files.completename
            .toLowerCase()
            .indexOf(action.data.toLocaleLowerCase()) !== -1
        );
      });
      return {
        ...state,
        search_employee_mobile_priv: search_mobile_priv,
      };
    case "system_loading":
      return {
        ...state,
        system_loading: action.data,
      };
    default:
      return state;
  }
};
export default dataReducer;
