const initialState = {
    ThirteenMonthGroup: [],
    EmployeeThirteenMonth: []
}
const dataReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'ThirteenMonthGroup':
            return {
                ...state,
                ThirteenMonthGroup: action.data
            }
        case 'EmployeeThirteenMonth':
            return {
                ...state,
                EmployeeThirteenMonth: action.data
            }
        case 'UpdateGroup':
            return {
                ...state,
                ThirteenMonthGroup:{
                    ...state.ThirteenMonthGroup,
                    ...action.data
                }
            }

        default:
            return state;
    }

}
export default dataReducer;