export const  navigationAction = {
    _userLoginDetails,
}

function _userLoginDetails(user_data){
    return dispatch =>{
        dispatch(despat(user_data))

    }
    function despat(data){return{type:"UserLoginData",data}}
}