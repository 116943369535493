const initialState = {
  userLoginData: [],
  userSchedule: [],
  appNav: [],
  gethandleBranch: [],
  company_name: [],
  SelectedBranches: [],
  AccountTypes: [],
  fetchHandleBranch: [],
  req_document: [],
  all_categories: [],
  department: [],
  positions: [],
  search_position: [],
  selected_position: [],
  questions: [],
  mental_questions: [],
  arith_questions: [],
  driver_questions: [],
  grading_categories: [],
  search_grading_categories: [],
  selected_grading_criteria: [],
  ticket_categories: [],
  criteria_total: 0,
  criteria_limit: false,
  selected_branch: [],
  selected_department: [],
  search_department: [],
};
const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UserLoginData":
      let sched = [];
      action.data.map((val) => {
        return (sched = val.schedule);
      });
      return {
        ...state,
        userLoginData: action.data,
        userSchedule: sched,
      };
    case "UserLoginData":
      return {
        ...state,
        userSchedule: action.data,
      };
    case "AppNav":
      return {
        ...state,
        appNav: action.data,
      };
    case "gethandleBranch":
      return {
        ...state,
        gethandleBranch: action.data,
      };
    case "company_name":
      return {
        ...state,
        company_name: action.data,
      };
    case "SelectedBranches":
      return {
        ...state,
        SelectedBranches: action.data,
      };
    case "AccountTypes":
      return {
        ...state,
        AccountTypes: action.data,
      };
    case "fetchHandleBranch":
      return {
        ...state,
        fetchHandleBranch: action.data,
      };

    case "req_document":
      return {
        ...state,
        req_document: action.data,
      };
    case "all_categories":
      return {
        ...state,
        all_categories: action.data,
      };

    case "navStateChange":
      return {
        ...state,
        ...action.data,
      };
    case "positions":
      return {
        ...state,
        positions: action.data,
        search_position: action.data,
      };
    case "searchpositions":
      let Position_search = state.positions.filter((files) => {
        return (
          files.userjob_position
            .toLowerCase()
            .indexOf(action.data.toLocaleLowerCase()) !== -1
        );
      });
      return {
        ...state,
        search_position: Position_search,
      };
    case "added_position":
      return {
        ...state,
        positions: state.positions.concat(action.data),
        search_position: state.search_position.concat(action.data),
      };
    case "questions":
      return {
        ...state,
        questions: action.data,
      };
    case "added_question":
      return {
        ...state,
        questions: state.questions.concat(action.data),
      };
    case "delete_question":
      return {
        ...state,
        questions: state.questions.filter(
          (val) => val.question_id != action.data.question_id
        ),
      };
    case "selected_position":
      return {
        ...state,
        selected_position: action.data,
      };
    case "onchange_selected_position":
      return {
        ...state,
        selected_position: {
          ...state.selected_position,
          ...action.data,
        },
      };
    case "update_position":
      state.positions.map((val) => {
        if (val.position_id == action.data.position_id) {
          val.userjob_position = action.data.userjob_position;
          val.position_suffix = action.data.position_suffix;
          val.position_code = action.data.position_code;
          val.hiring_count = action.data.hiring_count;
        }
      });
      state.search_position.map((val) => {
        if (val.company_id == action.data.company_id) {
          val.userjob_position = action.data.userjob_position;
          val.position_suffix = action.data.position_suffix;
          val.position_code = action.data.position_code;
          val.hiring_count = action.data.hiring_count;
        }
      });
      return {
        ...state,
      };
    case "delete_position":
      return {
        ...state,
        positions: state.positions.filter(
          (val) => val.position_id != action.data.position_id
        ),
        search_position: state.search_position.filter(
          (val) => val.position_id != action.data.position_id
        ),
      };
    case "mental_questions":
      return {
        ...state,
        mental_questions: action.data,
      };
    case "arith_questions":
      return {
        ...state,
        arith_questions: action.data,
      };
    case "driver_questions":
      return {
        ...state,
        driver_questions: action.data,
      };
    case "criteria":
      return {
        ...state,
        grading_categories: action.data,
        search_grading_categories: action.data,
      };
    case "added_criteria":
      return {
        ...state,
        grading_categories: state.grading_categories.concat(action.data),
        search_grading_categories: state.search_grading_categories.concat(
          action.data
        ),
      };
    case "selected_grading_criteria":
      return {
        ...state,
        selected_grading_criteria: action.data,
      };
    case "onchange_selected_grading_criteria":
      return {
        ...state,
        selected_grading_criteria: {
          ...state.selected_grading_criteria,
          ...action.data,
        },
      };
    case "update_grading_criteria":
      state.grading_categories.map((val) => {
        if (val.category_id == action.data.category_id) {
          val.category_details = action.data.category_details;
          val.amount = action.data.amount;
        }
      });
      state.search_grading_categories.map((val) => {
        if (val.category_id == action.data.category_id) {
          val.category_details = action.data.category_details;
          val.amount = action.data.amount;
        }
      });
      return {
        ...state,
      };
    case "delete_grading_criteria":
      return {
        ...state,
        grading_categories: state.grading_categories.filter(
          (val) => val.category_id != action.data.category_id
        ),
        search_grading_categories: state.search_grading_categories.filter(
          (val) => val.category_id != action.data.category_id
        ),
      };
    case "search_grading_categories":
      let criteria_search = state.grading_categories.filter((files) => {
        return (
          files.category_details
            .toLowerCase()
            .indexOf(action.data.toLocaleLowerCase()) !== -1
        );
      });
      return {
        ...state,
        search_grading_categories: criteria_search,
      };
    case "ticket_categories":
      return {
        ...state,
        ticket_categories: action.data,
      };
    case "criteria_total":
      return {
        ...state,
        criteria_total: action.data,
      };
    case "criteria_limit":
      return {
        ...state,
        criteria_limit: action.data,
      };
    case "added_branch":
      return {
        ...state,
        fetchHandleBranch: state.fetchHandleBranch.concat(action.data),
        gethandleBranch: state.gethandleBranch.concat(action.data)
      };
    case 'selected_branch':
      return {
        ...state,
        selected_branch: action.data,
      }
    case 'onchange_selected_branch':
      return {
        ...state,
        selected_branch: {
          ...state.selected_branch,
          ...action.data,
        }
      }

    case 'searchbranch':
      let BranchSearch = state.gethandleBranch.filter(
        (files) => {
          return files.branch_company.toLowerCase().indexOf(
            action.data.toLocaleLowerCase()) !== -1
        }
      )
      return {
        ...state,
        fetchHandleBranch: BranchSearch
      }

    case 'update_branch':
      state.gethandleBranch.map((val) => {
        if (val.branch_id == action.data.branch_id) {
          val.branch_name = action.data.branch_name
          val.branch_company = action.data.branch_company
          val.branch_location = action.data.branch_location
          val.branch_loc_description = action.data.branch_loc_description
          val.branch_code = action.data.branch_code
        }
      })
      state.fetchHandleBranch.map((val) => {
        if (val.branch_id == action.data.branch_id) {
          val.branch_name = action.data.branch_name
          val.branch_company = action.data.branch_company
          val.branch_location = action.data.branch_location
          val.branch_loc_description = action.data.branch_loc_description
          val.branch_code = action.data.branch_code
        }
      })
      return {
        ...state
      }
    case 'delete_branch':
      return {
        ...state,
        fetchHandleBranch: state.fetchHandleBranch.filter((val) => (val.branch_id != action.data.branch_id)),
        gethandleBranch: state.gethandleBranch.filter((val) => (val.branch_id != action.data.branch_id)),
      }
      case "added_department":
        return {
          ...state,
          department: state.department.concat(action.data),
          search_department: state.search_department.concat(action.data),
        };
        case 'selected_department':
          return {
            ...state,
            selected_department: action.data,
          }
          case 'update_department':
            state.department.map((val) => {
              if (val.dept_id == action.data.dept_id) {
                val.dept_name = action.data.dept_name
                val.dept_suffix = action.data.dept_suffix
                val.dept_code = action.data.dept_code
              }
            })
            state.search_department.map((val) => {
              if (val.dept_id == action.data.dept_id) {
                val.dept_name = action.data.dept_name
                val.dept_suffix = action.data.dept_suffix
                val.dept_code = action.data.dept_code
              }
            })
            return {
              ...state
            }
            case 'delete_department':
              return {
                ...state,
                search_department: state.search_department.filter((val) => (val.dept_id != action.data.dept_id)),
                department: state.department.filter((val) => (val.dept_id != action.data.dept_id)),
              }
              case 'onchange_selected_department':
                return {
                  ...state,
                  selected_department: {
                    ...state.selected_department,
                    ...action.data,
                  }
                }
                case 'searchdepartment':
                  let DepartmentSearch = state.department.filter(
                    (files) => {
                      return files.dept_name.toLowerCase().indexOf(
                        action.data.toLocaleLowerCase()) !== -1
                    }
                  )
                  return {
                    ...state,
                    search_department: DepartmentSearch
                  }
          
    default:
      return state;
  }
};
export default dataReducer;

function createData(schedule) {
  return { schedule };
}
