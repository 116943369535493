import moment from "moment";

const initialState = {
  payrollData: [],
  payrollGroup: [],
  payrollGroup2: [],
  branches: [],
  Listemp: [],
  deduction: [],
  earning: [],
  responsedata: [],
  Disburstment: [],
  selected_employee_payroll: [],
  payDetails: [],
  accomLoading: false,
  Deduct_name: [],
  deductionDetails: [],
  searchDeductionDetails: [],
  Earning_name: [],
  earningDetails: [],
  searchEarningDetails: [],
  Loading: false,
  Holidays: [],
  late_employees: [],
  additionalAdjustment: [],
  SearchadditionalAdjustment: [],
  overWriteList: [],
  deductionAdjustment: [],
  SearchdeductionAdjustment: [],
  branchListPayrollApprover: [],
  branch_Hierarchy: [],
  employeeSett: [],
  searchemployeeSett: [],
  SelectedBranchWages: [],
  UploadTimesheetFile: [],
  UploadTimesheetData: [],
  deminimis: [],
  jo_name: [],
  selectedGroupId: "",
  filter_from: "",
  filter_to: "",
  page: 0,
  rowsPerPage: 10,
  rowCount: 0,
  dateFilterStart: "",
  dateFilterEnd: "",
  search: "",
};
const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case "onChangePayroll":
      return {
        ...state,
        ...action.data,
      };
    case "PayrollData":
      return {
        ...state,
        payrollData: action.data,
      };
    case "PayrollGroup":
      return {
        ...state,
        payrollGroup: action.data,
        selectedGroupId: action.selectedGroupId,
      };
    case "PayrollGroup2":
      return {
        ...state,
        payrollGroup2: action.data,
      };
    case "GetEmployeePayroll":
      return {
        ...state,
        ...action.data,
      };
    case "SelectedEmployeePayroll":
      return {
        ...state,
        selected_employee_payroll: action.data,
      };
    case "ViewPayrollDetails":
      return {
        ...state,
        payDetails: action.payDetails,
        responsedata: action.responsedata,
      };
    case "UpdateDeductionEarnings":
      return {
        ...state,
        deduction: action.deduction,
        earning: action.earning,
      };
    case "accomLoading":
      return {
        ...state,
        accomLoading: action.data,
      };
    case "ApproveStatus":
      state.payrollGroup.status = action.data;
      state.payrollGroup2.status = action.data;

      return {
        ...state,
      };
    case "Deduction":
      return {
        ...state,
        Deduct_name: action.Deduct_name,
        deductionDetails: action.deductionDetails,
        Earning_name: action.Earning_name,
        earningDetails: action.earningDetails,
        searchEarningDetails: action.earningDetails,
        searchDeductionDetails: action.deductionDetails,
      };
    case "Deduction_name":
      return {
        ...state,
        Deduct_name: action.Deduct_name,
      };
    case "Deduction_data":
      return {
        ...state,
        deductionDetails: action.deductionDetails,
        searchDeductionDetails: action.deductionDetails,
      };
    case "Delete_transaction":
      return {
        ...state,
        deductionDetails: state.deductionDetails.filter(
          (val) => val.deduction_id !== action.data
        ),
        searchDeductionDetails: state.searchDeductionDetails.filter(
          (val) => val.deduction_id !== action.data
        ),
      };
    case "Update_transaction":
      state.deductionDetails.map((val) => {
        if (val.transaction_id === action.data.trans_id) {
          val.amount = action.data.amount;
          val.date_from = action.data.date_from;
          val.date_to = action.data.date_to;
          val.cut_off = action.data.cut_off;
        }
      });
      state.searchDeductionDetails.map((val) => {
        if (val.transaction_id === action.data.trans_id) {
          val.amount = action.data.amount;
          val.date_from = action.data.date_from;
          val.date_to = action.data.date_to;
          val.cut_off = action.data.cut_off;
        }
      });
      return {
        ...state,
      };
    case "searchDeduction":
      let EmployeeSearch = state.deductionDetails.filter((files) => {
        if (files.user_lname !== null)
          return (
            files.user_lname
              .toLowerCase()
              .indexOf(action.data.toLocaleLowerCase()) !== -1 ||
            files.user_fname
              .toLowerCase()
              .indexOf(action.data.toLocaleLowerCase()) !== -1
          );
      });
      return {
        ...state,
        searchDeductionDetails: EmployeeSearch,
      };
    case "Earning_name":
      return {
        ...state,
        Earning_name: action.Earning_name,
      };
    case "Earning_data":
      return {
        ...state,
        earningDetails: action.earningDetails,
        searchEarningDetails: action.earningDetails,
      };
    case "Delete_transaction_Earning":
      return {
        ...state,
        earningDetails: state.earningDetails.filter(
          (val) => val.deduction_id !== action.data
        ),
        searchEarningDetails: state.searchEarningDetails.filter(
          (val) => val.deduction_id !== action.data
        ),
      };
    case "Update_transactionEarning":
      state.earningDetails.map((val) => {
        if (val.transaction_id === action.data.trans_id) {
          val.amount = action.data.amount;
          val.date_from = action.data.date_from;
          val.date_to = action.data.date_to;
          val.cut_off = action.data.cut_off;
          val.deminimis_benefits = action.data.deminimis_benefits;
        }
      });
      state.searchEarningDetails.map((val) => {
        if (val.transaction_id === action.data.trans_id) {
          val.amount = action.data.amount;
          val.date_from = action.data.date_from;
          val.date_to = action.data.date_to;
          val.cut_off = action.data.cut_off;
          val.deminimis_benefits = action.data.deminimis_benefits;
        }
      });
      return {
        ...state,
      };
    case "searchEarning":
      let EmployeeSearch2 = state.earningDetails.filter((files) => {
        if (files.user_lname !== null)
          return (
            files.user_lname
              .toLowerCase()
              .indexOf(action.data.toLocaleLowerCase()) !== -1 ||
            files.user_fname
              .toLowerCase()
              .indexOf(action.data.toLocaleLowerCase()) !== -1
          );
      });
      return {
        ...state,
        searchEarningDetails: EmployeeSearch2,
      };
    case "Loading":
      return {
        ...state,
        Loading: action.data,
      };
    case "Holidays":
      return {
        ...state,
        Holidays: action.data,
      };
    case "DeleteHoliday":
      return {
        ...state,
        Holidays: state.Holidays.filter((val) => val.id !== action.data),
      };
    case "DeletePayroll":
      return {
        ...state,
        payrollData: state.payrollData.filter(
          (val) => val.p_id !== action.data
        ),
      };
    case "PayrollSettingEmployee":
      return {
        ...state,
        late_employees: action.data,
      };
    case "UpdateLate":
      state.late_employees.map((val) => {
        if (val.user_id === action.user_id) {
          val.late_settings = action.data;
        }
      });
      return {
        ...state,
      };
    case "AdditionalAdjustment":
      return {
        ...state,
        additionalAdjustment: action.data,
        SearchadditionalAdjustment: action.data,
      };
    case "InsertOverwrite":
      return {
        ...state,
        overWriteList: state.overWriteList.concat(action.data),
      };
    case "DeleteOverwrite":
      return {
        ...state,
        overWriteList: state.overWriteList.concat(action.data),
      };
    case "UpdateAdditionalAdjustment":
      state.additionalAdjustment.map((val) => {
        if (val.req_id == action.req_id) {
          val.req_date_start = action.data.req_date_start;
          val.req_date_end = action.data.req_date_end;
          val.req_hours_approved = action.data.req_hours_approved;
          val.req_quantity = action.data.req_quantity;
          val.req_justification = action.data.req_justification;
        }
        return true;
      });
      state.SearchadditionalAdjustment.map((val) => {
        if (val.req_id == action.req_id) {
          val.req_date_start = action.data.req_date_start;
          val.req_date_end = action.data.req_date_end;
          val.req_hours_approved = action.data.req_hours_approved;
          val.req_quantity = action.data.req_quantity;
          val.req_justification = action.data.req_justification;
        }
        return true;
      });
      return {
        ...state,
      };
    case "DeleteAdditionalAdjustment":
      return {
        ...state,
        additionalAdjustment: state.additionalAdjustment.filter(
          (val) => val.req_id !== action.req_id
        ),
        SearchadditionalAdjustment: state.SearchadditionalAdjustment.filter(
          (val) => val.req_id !== action.req_id
        ),
      };
    case "searchAdjustment":
      let EmployeeSearch4 = state.additionalAdjustment.filter((files) => {
        if (files.user_lname !== null)
          return (
            files.user_lname
              .toLowerCase()
              .indexOf(action.data.toLocaleLowerCase()) !== -1 ||
            files.user_fname
              .toLowerCase()
              .indexOf(action.data.toLocaleLowerCase()) !== -1
          );
      });
      return {
        ...state,
        SearchadditionalAdjustment: EmployeeSearch4,
      };
    case "DeductionAdjustment":
      return {
        ...state,
        deductionAdjustment: action.data,
        SearchdeductionAdjustment: action.data,
      };
    case "BranchListPayrollApprover":
      return {
        ...state,
        branchListPayrollApprover: action.data,
      };
    case "Branch_Hierarchy":
      return {
        ...state,
        branch_Hierarchy: action.data,
      };
    case "PayrollSettingEmployee2":
      return {
        ...state,
        employeeSett: action.data,
        searchemployeeSett: action.data,
      };
    case "SearchEmployeeSett":
      let EmployeeSearch5 = state.employeeSett.filter((files) => {
        if (files.user_lname !== null)
          return (
            files.user_lname
              .toLowerCase()
              .indexOf(action.data.toLocaleLowerCase()) !== -1 ||
            files.user_fname
              .toLowerCase()
              .indexOf(action.data.toLocaleLowerCase()) !== -1
          );
      });
      return {
        ...state,
        searchemployeeSett: EmployeeSearch5,
      };
    case "SelectedBranchWages":
      return {
        ...state,
        SelectedBranchWages: action.data,
      };
    case "onChangeWages":
      return {
        ...state,
        SelectedBranchWages: {
          ...state.SelectedBranchWages,
          ...action.data,
        },
      };
    case "onChangeTextConti":
      return {
        ...state,
        selectedEmployee: {
          ...state.selectedEmployee,
          ...action.data,
        },
      };

    case "UploadTimesheetFile":
      return {
        ...state,
        UploadTimesheetFile: action.data,
      };
    case "deleteTimesheet":
      return {
        ...state,
        UploadTimesheetFile: state.UploadTimesheetFile.filter(
          (val) => val.upload_timesheet_id !== action.data
        ),
      };
    case "UploadTimesheetDataUP":
      return {
        ...state,
        UploadTimesheetData: action.data,
      };

    // case 'UpdateStructure':
    //     state.branchListPayrollApprover.map((val) => {
    //         if (action.branch_id == val.branch_id) {
    //             val.payroll_hierarchy = JSON.stringify(action.data)
    //         }
    //     })
    //     return {
    //         ...state,
    //     }

    default:
      return state;
  }
};
export default dataReducer;
