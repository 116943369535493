const initialState = {
    attendanceDetails: [],
    attendanceHeader: [],
    timesheet: [],
    searchTable: [],

}
const dataReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'todayAttendance':
            return {
                ...state,
                attendanceDetails: action.data.details,
                searchTable: action.data.details,
            };
        case 'attendanceHeader':
            return {
                ...state,
                attendanceHeader: action.data,
            }
        case 'timesheet':
            return {
                ...state,
                timesheet: action.data,
            }
        case 'search':
            let EmployeeSearch = state.attendanceDetails.filter(
                (files) => {
                    return files.employeename.toLowerCase().indexOf(
                        action.data.toLocaleLowerCase()) !== -1 
                }
            )
            return {
                ...state,
                searchTable: EmployeeSearch
            }
        default:
            return state;
    }
}
export default dataReducer;