import React from "react";
import { Component } from "react";
export default function asyncComponent(importComponent) {
  //   const AsyncComponent = () => {
  //     const [state, setState] = React.useState({
  //       component: null,
  //     });
  //     React.useEffect(() => {
  //       const getComponent = async () => {
  //         const { component } = importComponent;
  //         console.log(importComponent);
  //         setState((prev) => ({
  //           ...prev,
  //           component: importComponent,
  //         }));
  //       };

  //       getComponent();
  //     }, []);
  //     const C = state.component;

  //     return <div>{C ? <C {...importComponent} /> : null}</div>;
  //   };
  class AsyncComponent extends Component {
    constructor(props) {
      super(props);
      this.state = {
        component: null,
      };
    }
    async componentDidMount() {
      const { default: component } = await importComponent();
      this.setState({
        component: component,
      });
    }
    render() {
      const C = this.state.component;
      return C ? <C {...this.props} /> : <h1>Loading...</h1>;
    }
  }

  return AsyncComponent;
}
