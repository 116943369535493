const initialState = {
  applicant_master: [],
  applicant_search: [],
  applicant_submit: [],
  applicant: [],
  applicant_loading: false
}
const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'applicant_master':
      return {
        ...state,
        applicant_master: action.data,
        applicant_search: action.data
      }
    case 'applicant_loading':
      return {
        ...state,
        applicant_loading: action.data
      }
    case 'applicant':
      return {
        ...state,
        applicant: action.data,
        applicant_submit: action.data
      }
    case 'delete_applicant':
      return {
        ...state,
        applicant_master: state.applicant_master.filter(
          val => val.user_id != action.data.user_id
        ),
        applicant_search: state.applicant_search.filter(
          val => val.user_id != action.data.user_id
        )
      }
    case 'hired_applicant':
      return {
        ...state,
        applicant_master: state.applicant_master.filter(
          val => val.user_id != action.data.user_id
        ),
        applicant_search: state.applicant_search.filter(
          val => val.user_id != action.data.user_id
        )
      }
    default:
      return state
  }
}
export default dataReducer
