import AppBar from "@material-ui/core/AppBar";
import Avatar from "@material-ui/core/Avatar";
import Backdrop from "@material-ui/core/Backdrop";
import Badge from "@material-ui/core/Badge";
import Button from "@material-ui/core/Button";
import Collapse from "@material-ui/core/Collapse";
import CssBaseline from "@material-ui/core/CssBaseline";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import Link from "@material-ui/core/Link";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Snackbar from "@material-ui/core/Snackbar";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import AccountBalanceOutlinedIcon from "@material-ui/icons/AccountBalanceOutlined";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import AccountBalanceWalletOutlinedIcon from "@material-ui/icons/AccountBalanceWalletOutlined";
import AccountCircle from "@material-ui/icons/AccountCircle";
import AccountTreeOutlinedIcon from "@material-ui/icons/AccountTreeOutlined";
import AddBoxIcon from "@material-ui/icons/AddBox";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import AlarmAddOutlinedIcon from "@material-ui/icons/AlarmAddOutlined";
import AppsOutlinedIcon from "@material-ui/icons/AppsOutlined";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import BallotOutlinedIcon from "@material-ui/icons/BallotOutlined";
import BeachAccessOutlinedIcon from "@material-ui/icons/BeachAccessOutlined";
import BeenhereOutlinedIcon from "@material-ui/icons/BeenhereOutlined";
import BookIcon from "@material-ui/icons/Book";
import BorderColorOutlinedIcon from "@material-ui/icons/BorderColorOutlined";
import BusinessCenterOutlinedIcon from "@material-ui/icons/BusinessCenterOutlined";
import BusinessOutlinedIcon from "@material-ui/icons/BusinessOutlined";
import CardTravelIcon from "@material-ui/icons/CardTravel";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import CommuteIcon from "@material-ui/icons/Commute";
import DateRangeIcon from "@material-ui/icons/DateRange";
import DescriptionIcon from "@material-ui/icons/Description";
import DeviceHubIcon from "@material-ui/icons/DeviceHub";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import DvrIcon from "@material-ui/icons/Dvr";
import EmojiTransportationIcon from "@material-ui/icons/EmojiTransportation";
import EventIcon from "@material-ui/icons/Event";
import EventBusyOutlinedIcon from "@material-ui/icons/EventBusyOutlined";
import EventNoteIcon from "@material-ui/icons/EventNote";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ExposureIcon from "@material-ui/icons/Exposure";
import FolderSharedOutlinedIcon from "@material-ui/icons/FolderSharedOutlined";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import GavelIcon from "@material-ui/icons/Gavel";
import GradeIcon from "@material-ui/icons/Grade";
import GroupIcon from "@material-ui/icons/Group";
import HelpIcon from "@material-ui/icons/Help";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import HomeWorkIcon from "@material-ui/icons/HomeWork";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import HowToRegIcon from "@material-ui/icons/HowToReg";
import IndeterminateCheckBoxIcon from "@material-ui/icons/IndeterminateCheckBox";
import InsertChartOutlinedIcon from "@material-ui/icons/InsertChartOutlined";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import ListAltIcon from "@material-ui/icons/ListAlt";
import LocalGasStationIcon from "@material-ui/icons/LocalGasStation";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import MailIcon from "@material-ui/icons/Mail";
import MenuIcon from "@material-ui/icons/Menu";
import MonetizationOnOutlinedIcon from "@material-ui/icons/MonetizationOnOutlined";
import MoreIcon from "@material-ui/icons/MoreVert";
import NavigationOutlinedIcon from "@material-ui/icons/NavigationOutlined";
import NotificationsIcon from "@material-ui/icons/Notifications";
import PhonelinkSetupIcon from "@material-ui/icons/PhonelinkSetup";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
import PublishIcon from "@material-ui/icons/Publish";
import RecentActorsIcon from "@material-ui/icons/RecentActors";
import RecordVoiceOverIcon from "@material-ui/icons/RecordVoiceOver";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import SettingsApplicationsOutlinedIcon from "@material-ui/icons/SettingsApplicationsOutlined";
import SnoozeOutlinedIcon from "@material-ui/icons/SnoozeOutlined";
import StarHalfOutlinedIcon from "@material-ui/icons/StarHalfOutlined";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import SwapVertIcon from "@material-ui/icons/SwapVert";
import TimelapseOutlinedIcon from "@material-ui/icons/TimelapseOutlined";
import TimelineIcon from "@material-ui/icons/Timeline";
import TrendingDownIcon from "@material-ui/icons/TrendingDown";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import TuneOutlinedIcon from "@material-ui/icons/TuneOutlined";
import VideogameAssetOutlinedIcon from "@material-ui/icons/VideogameAssetOutlined";
import VpnKeyOutlinedIcon from "@material-ui/icons/VpnKeyOutlined";
import WidgetsOutlinedIcon from "@material-ui/icons/WidgetsOutlined";
import WorkOutlineIcon from "@material-ui/icons/WorkOutline";
import MuiAlert from "@material-ui/lab/Alert";
import clsx from "clsx";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link as NewLink, useHistory } from "react-router-dom";
import { navigationAction } from "../../components/apps/action/navigationAction";
import LogoIMG from "../../media/pockethr.png";
import { getData } from "../api/api";
import {
  fetchAccountTypes,
  fetchQuestion,
  getAccountCategories,
  getAllHandleBranch,
  getApprovingRequest,
  GetCategoryTypeDetails,
  getHandleBranch,
  getPosition,
  GetTicketCategories,
  getUserDetailsbyID,
  getUserLoginData,
} from "../apps/Functions/home_func";
import { betawebsitelink } from "../http/ht";
import SelfDetails from "./personal_details/personal_details";
import Ticketing from "./ticket/ticket";
const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  grow: {
    flexGrow: 1,
  },
  appBar: {
    background: "#006064",
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    height: "100%",
    overflow: "auto",
  },
  drawerPaper: {
    width: drawerWidth,
    height: "100%",
    overflow: "auto",
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  backdrop: {
    zIndex: 99,
    color: "#fff",
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
export default function PersistentDrawerLeft() {
  const history = useHistory();
  const dispatch = useDispatch();
  const dispatch_data = (type, data) => {
    dispatch({
      type: type,
      data: data,
    });
  };
  const navigation_reducer = useSelector((state) => state.Navigation);
  const sheet_reducer = useSelector((state) => state.Sheet);
  const payrollRequestCount = useSelector(
    (state) => state.Home.payrollRequestCount
  );

  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery("(max-width:959px)");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const menuId = "primary-search-account-menu";
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const [ticket, setTicket] = React.useState(false);
  const handleOpenDialogTicket = () => {
    setTicket(true);
  };
  const handleCloseDialogTicket = () => {
    setTicket(false);
  };
  const [state, setState] = React.useState({
    timesheet: false,
    attendance: false,
    payroll: false,
    open: false,
    system: false,
    application: false,
    retro: false,
    adjustments: false,
    payrollSettings: false,
    equipment: false,
    memo: false,
    vehicle: false,
    request_logs: [],
    attendance_pages: [],
    payroll_pages: [],
    retro_pages: [],
    payroll_adjustments_pages: [],
    payroll_settings_pages: [],
    app_settings_pages: [],
    system_settings_pages: [],
    vehicle_pages: [],
    uploading: false,
    upload_pages: [],
    equipments: [],
    memorandum: [],
    vertical: "top",
    horizontal: "center",
    req_count: 0,
    retro_pagesv2: [],
  });
  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const timesheetClick = () => {
    setState({
      ...state,
      timesheet: !state.timesheet,
      attendance: false,
      upload: false,
      payroll: false,
      system: false,
      application: false,
      equipment: false,
      memo: false,
      vehicle: false,
    });
  };
  const attendanceClick = () => {
    setState({
      ...state,
      attendance: !state.attendance,
      timesheet: false,
      payroll: false,
      upload: false,
      system: false,
      application: false,
      equipment: false,
      memo: false,
      vehicle: false,
    });
  };
  const payrollClick = () => {
    setState({
      ...state,
      payroll: !state.payroll,
      timesheet: false,
      attendance: false,
      upload: false,
      system: false,
      application: false,
      equipment: false,
      memo: false,
      vehicle: false,
    });
  };
  const retroClick = () => {
    setState({
      ...state,
      retro: !state.retro,
      timesheet: false,
      timesheet: false,
      attendance: false,
      system: false,
      application: false,
      payrollSettings: false,
      upload: false,
      adjustments: false,
      equipment: false,
      memo: false,
      vehicle: false,
    });
  };
  const adjustmentsClick = () => {
    setState({
      ...state,
      adjustments: !state.adjustments,
      retro: false,
      timesheet: false,
      timesheet: false,
      attendance: false,
      system: false,
      upload: false,
      application: false,
      payrollSettings: false,
      equipment: false,
      memo: false,
      vehicle: false,
    });
  };
  const uploadClick = () => {
    setState({
      ...state,
      upload: !state.upload,
      retro: false,
      timesheet: false,
      adjustments: false,
      attendance: false,
      system: false,
      application: false,
      payrollSettings: false,
      payroll: false,
      equipment: false,
      memo: false,
      vehicle: false,
    });
  };
  const payrollSettingsClick = () => {
    setState({
      ...state,
      payrollSettings: !state.payrollSettings,
      retro: false,
      timesheet: false,
      timesheet: false,
      attendance: false,
      upload: false,
      system: false,
      application: false,
      adjustments: false,
      equipment: false,
      memo: false,
      vehicle: false,
    });
  };
  const vehicleClick = () => {
    setState({
      ...state,
      payroll: false,
      upload: false,
      timesheet: false,
      attendance: false,
      system: false,
      vehicle: !state.vehicle,
      application: false,
      equipment: false,
      memo: false,
    });
  };
  const systemClick = () => {
    setState({
      ...state,
      payroll: false,
      upload: false,
      timesheet: false,
      attendance: false,
      system: !state.system,
      application: false,
      equipment: false,
      memo: false,
      vehicle: false,
    });
  };
  const applicationClick = () => {
    setState({
      ...state,
      payroll: false,
      upload: false,
      timesheet: false,
      attendance: false,
      system: false,
      application: !state.application,
      equipment: false,
      memo: false,
      vehicle: false,
    });
  };
  const MemoClick = () => {
    setState({
      ...state,
      timesheet: false,
      attendance: false,
      upload: false,
      payroll: false,
      system: false,
      application: false,
      equipment: false,
      memo: !state.memo,
      vehicle: false,
    });
  };
  const imglogo = LogoIMG;
  const handleDrawerOpen = () => {
    setState({
      ...state,
      open: true,
    });
  };
  const handleDrawerClose = () => {
    setState({
      ...state,
      timesheet: false,
      attendance: false,
      payroll: false,
      open: false,
      system: false,
      application: false,
    });
  };
  const handleClose = () => {
    setState({
      ...state,
      timesheet: false,
      attendance: false,
      payroll: false,
      open: false,
      system: false,
      application: false,
    });
  };
  const EquipmentClick = () => {
    setState({
      ...state,
      timesheet: false,
      attendance: false,
      upload: false,
      payroll: false,
      system: false,
      application: false,
      equipment: !state.equipment,
      memo: false,
    });
  };
  useEffect(() => {
    let data = {
      category_type: "Grading Criteria",
    };
    dispatch({
      type: "activitiy_loading",
      data: false,
    });
    dispatch({
      type: "accomLoading",
      data: false,
    });
    dispatch({
      type: "requestLoading",
      data: false,
    });
    dispatch_data("createdHierarchy", []);
    dispatch({
      type: "createdHierarchyLoading",
      data: true,
    });
    async function getCreatedHierarchy() {
      await getData("users/Get_users", {
        u_id: localStorage.getItem("u"),
        type: "admin",
        date: new Date(),
      }).then((response) => {
        dispatch_data("createdHierarchy", response.hierarchy_structures);
        dispatch_data("createdHierarchyTypes", response.request_type);
      });
      dispatch({
        type: "createdHierarchyLoading",
        data: false,
      });
    }
    async function fetchData() {
      await getUserLoginData().then((response) => {
        dispatch(navigationAction._userLoginDetails(response.user_login_data));
        dispatch({
          type: "DarLoading",
          data: false,
        });
        dispatch({
          type: "navStateChange",
          data: {
            department: response.department,
            search_department: response.department,
            all_categories: response.categories,
            appNav: response.app_nav,
          },
        });
        const requestlog = response.app_nav.filter(function (item) {
          return (
            item.page_name === "Leave Sheet" ||
            item.page_name === "OB Sheet" ||
            item.page_name === "Overtime Sheet" ||
            item.page_name === "Accomplishments" ||
            item.page_name === "Pending Requests" ||
            item.page_name === "Document Sheet" ||
            item.page_name === "Work From Home" ||
            item.page_name === "Undertime Sheet" ||
            item.page_name === "Employee Appeal" ||
            item.page_name === "Change Duty" ||
            item.page_name === "Advance Duty" ||
            item.page_name === "Request Sheet"
          );
        });
        const attendancepages = response.app_nav.filter(function (item) {
          return (
            item.page_name === "Attendance Dashboard" ||
            item.page_name === "Attendance Monitoring" ||
            item.page_name === "Overwrite" ||
            item.page_name === "Manual Absent" ||
            item.page_name === "Late Settings" ||
            item.page_name === "App Logs" ||
            item.page_name === "DTR" ||
            item.page_name === "Monitoring Sheet" ||
            item.page_name === "Employee Sheet"
          );
        });
        const payrollspages = response.app_nav.filter(function (item) {
          return (
            item.page_name === "Holidays" ||
            item.page_name === "Payroll Group" ||
            item.page_name === "Government Dues" ||
            item.page_name === "Thirteenth Month Pay" ||
            item.page_name === "Payroll Requests" ||
            item.page_name === "Salary Settings" ||
            item.page_name === "Payroll Reports" ||
            item.page_name === "EarningsV2" ||
            item.page_name === "DeductionsV2"
          );
        });
        const retropages = response.app_nav.filter(function (item) {
          return (
            item.page_name === "Retro Earnings" ||
            item.page_name === "Retro Deductions"
          );
        });
        // const retropagesv2 = response.app_nav.filter(function (item) {
        //   return (
        //     item.page_name === "EarningsV2" || item.page_name === "DeductionsV2"
        //   );
        // });
        const payrolladjustmentspages = response.app_nav.filter(function (
          item
        ) {
          return (
            item.page_name === "Additional Adjustment" ||
            item.page_name === "Deduction Adjustment"
          );
        });
        const payrollsettingspages = response.app_nav.filter(function (item) {
          return (
            item.page_name === "Payroll Hierarchy" ||
            item.page_name === "Holiday And Wages Rate" ||
            item.page_name === "Payroll Rules" ||
            item.page_name === "Payroll Rule V2"
          );
        });
        const appsettingspages = response.app_nav.filter(function (item) {
          return item.page_name === "Admin Control";
        });
        const systemsettingspages = response.app_nav.filter(function (item) {
          return (
            item.page_name === "Account Priviledge" ||
            item.page_name === "Navigation Settings" ||
            item.page_name === "Company Settings" ||
            item.page_name === "Branch Settings" ||
            item.page_name === "Department Settings" ||
            item.page_name === "Position Settings" ||
            item.page_name === "Mobile Priviledge" ||
            item.page_name === "Time Table Settings" ||
            item.page_name === "Trip Rates" ||
            item.page_name === "Schedule Settings" ||
            item.page_name === "Request Hierarchy Settings" ||
            item.page_name === "Question Bank" ||
            item.page_name === "Grading Criteria"
          );
        });
        const uploadpages = response.app_nav.filter(function (item) {
          return (
            item.page_name === "Upload TimeSheet" ||
            item.page_name === "Upload DoubleBook" ||
            item.page_name === "Upload JobOrder" ||
            item.page_name === "Upload Trips"
          );
        });
        const equipmentpages = response.app_nav.filter(function (item) {
          return (
            item.page_name === "Inventory" ||
            item.page_name === "Inventory Item"
          );
        });
        const memopages = response.app_nav.filter(function (item) {
          return item.page_name === "Memo" || item.page_name === "NTE Absent";
        });
        const vehiclepages = response.app_nav.filter(function (item) {
          return (
            item.page_name === "Vehicles" ||
            item.page_name === "Vehicle Dashboard" ||
            item.page_name === "Budget Portal" ||
            item.page_name === "Fuel Portal"
          );
        });
        // payrollspages.forEach((val) => {
        //   if (val.page_name === "EarningsV2") {
        //     val.page_name = "Retro";
        //   }
        //   if (val.page_name === "DeductionsV2") {
        //     val.page_name = "Deduction";
        //   }
        // });
        payrollspages.sort(function (a, b) {
          return a["page_name"].localeCompare(b["page_name"]);
        });
        setState({
          ...state,
          request_logs: requestlog,
          attendance_pages: attendancepages,
          payroll_pages: payrollspages,
          retro_pages: retropages,
          // retro_pagesv2: retropagesv2,
          payroll_adjustments_pages: payrolladjustmentspages,
          payroll_settings_pages: payrollsettingspages,
          app_settings_pages: appsettingspages,
          system_settings_pages: systemsettingspages,
          upload_pages: uploadpages,
          equipments: equipmentpages,
          memorandum: memopages,
          vehicle_pages: vehiclepages,
        });
      });
    }
    async function handleBranch() {
      await getHandleBranch({ user_id: localStorage.getItem("u") }).then(
        (response) => {
          let company = [];
          response.response.map((item) => {
            let match = false;
            company.map((val) => {
              if (val.company_name == item.company_name) {
                match = true;
              }
            });
            if (!match) {
              company.push({
                company_name: item.company_name,
                company_id: item.company_id,
              });
            }
          });
          dispatch_data("gethandleBranch", response.response);
          dispatch_data("company_name", company);
          dispatch_data("SelectedBranches", []);
        }
      );
    }
    async function fetchAccountType() {
      await fetchAccountTypes().then((response) => {
        getApprovingRequest({ user_id: localStorage.getItem("u") }).then(
          (res) => {
            dispatch_data("req_count", res.approval_requests.length);
            dispatch_data("req_approving", res.approval_requests);
            // dispatch_data('req_count', 0)
          }
        );
        dispatch_data("AccountTypes", response);
      });
    }
    async function fetchHandleBranch() {
      await getAllHandleBranch({
        u_id: localStorage.getItem("u"),
        type: "admin",
        date: new Date(),
      }).then((response) => {
        dispatch_data("fetchHandleBranch", response);
      });
    }
    async function fetchDocCat() {
      let data = {
        company_id:
          navigation_reducer.userLoginData.length !== 0
            ? navigation_reducer.userLoginData[0].company_id
            : "",
        app_id: 7,
      };
      await getAccountCategories(data).then((response) => {
        let docs = [];
        response.map((item) => {
          if (item.category_type === "Request Document Type") {
            docs.push({
              category_details: item.category_details,
            });
          }
        });
        dispatch_data("req_document", docs);
      });
    }
    async function fetchAllPosition() {
      await getPosition().then((response) => {
        dispatch_data("positions", response);
      });
    }
    async function fetchAllQuestion() {
      await fetchQuestion().then((response) => {
        dispatch_data("questions", response);
      });
    }
    async function fetchCategoryTypeDetails() {
      await GetCategoryTypeDetails(data).then((response) => {
        let total = 0;
        response.data.map((val) => {
          total += parseFloat(val.amount);
        });
        if (total > 70) {
          dispatch_data("criteria_limit", true);
        } else {
          dispatch_data("criteria_limit", false);
        }
        dispatch_data("criteria", response.data);
        dispatch_data("criteria_total", total);
      });
    }
    async function fetchTicketCategories() {
      await GetTicketCategories().then((response) => {
        dispatch_data("ticket_categories", response);
      });
    }
    setTimeout(() => {
      fetchData();
      handleBranch();
      fetchAccountType();
      fetchHandleBranch();
      fetchDocCat();
      getCreatedHierarchy();
      fetchAllPosition();
      fetchAllQuestion();
      fetchCategoryTypeDetails();
      fetchTicketCategories();
    }, 1000);
  }, []);
  const logout = () => {
    localStorage.clear();
    window.location.replace(betawebsitelink);
    // window.location.replace("http://localhost:3000/#/")
    setTimeout(() => {
      window.location.reload();
    }, 500);
  };
  const fullScreen = useMediaQuery(theme.breakpoints.down("xl"));
  const [open, setOpen] = React.useState(false);
  const handleOpenDialog = () => {
    let data = {
      user_id: localStorage.getItem("u"),
    };
    getUserDetailsbyID(data).then((response) => {
      response.map((val) => {
        dispatch_data("personal_details", val);
        setOpen(true);
      });
    });
  };
  const handleCloseDialog = () => {
    setOpen(false);
  };
  const [alert, setAlert] = React.useState(false);

  const handleAlertClick = () => {
    setAlert(true);
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setAlert(false);
  };
  const { vertical, horizontal } = state;
  const mobileMenuId = "primary-search-account-menu-mobile";
  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>
        <Badge color="secondary" variant="dot">
          <Link
            href="#/pending_request/"
            style={{ textDecoration: "none", color: "black" }}
          >
            <Typography>Pending Request</Typography>
          </Link>
        </Badge>
      </MenuItem>
    </Menu>
  );
  const onClickRoute = () => {
    history.push("/payrollRequest/");
  };
  const [dialog, setDialog] = React.useState(false);
  const handleDialogOpen = () => {
    setDialog(true);
  };

  const handleDialoglClose = () => {
    setDialog(false);
  };
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="secondary">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem>
        <IconButton aria-label="show 11 new notifications" color="inherit">
          <Badge badgeContent={11} color="secondary">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );
  return (
    <>
      <CssBaseline />
      {/* <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      > */}
      <Snackbar
        open={alert}
        autoHideDuration={6000}
        onClose={() => {
          handleAlertClose();
        }}
        key={(vertical, horizontal)}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleAlertClose} severity="info">
          This function is still under development!
        </Alert>
      </Snackbar>
      <AppBar position="fixed" className={clsx(classes.appBar)}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, state.open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          {/* {!matches ? <> */}
          <Link href="/#/">
            <Avatar
              variant="square"
              alt="picture"
              src={imglogo}
              style={{ width: 125, height: "auto" }}
            />
          </Link>

          <div className={classes.grow} />
          <div>
            {/* <IconButton aria-label="show 4 new mails" color="inherit">
              <Badge badgeContent={4} color="secondary">
                <MailIcon />
              </Badge>
            </IconButton> */}
            <IconButton
              onClick={onClickRoute}
              aria-label="show 17 new notifications"
              color="inherit"
            >
              <Badge badgeContent={payrollRequestCount} color="secondary">
                {/* <Link
                  href="#/payrollRequest/"
                  style={{ textDecoration: "none", color: "black" }}
                > */}
                <AccountBalanceIcon style={{ color: "#fff" }} />
                {/* </Link> */}
              </Badge>
            </IconButton>
            <IconButton
              onClick={handleProfileMenuOpen}
              aria-label="show 17 new notifications"
              color="inherit"
            >
              <Badge badgeContent={sheet_reducer.req_count} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton>
            <IconButton
              edge="end"
              aria-label="account of current user"
              // aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleOpenDialog}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            {/* <IconButton
              edge="end"
              onClick={() => {
                handleOpenDialogTicket();
              }}
              color="inherit"
            >
              <HelpIcon />
            </IconButton> */}
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMenu}

      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={state.open}
        classes={{ paper: classes.drawerPaper }}
      >
        <div className={classes.drawerHeader}>
          <Button
            variant="contained"
            className={classes.button}
            onClick={handleDrawerClose}
            style={{ backgroundColor: "#009197", color: "white" }}
          >
            Hide
            {theme.direction === "ltr" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </Button>
        </div>
        <List
          component="nav"
          className={classes.liststyle}
          aria-label="mailbox folders"
        >
          <NewLink to="/" style={{ textDecoration: "none", color: "black" }}>
            <ListItem button onClick={handleDrawerClose}>
              <ListItemIcon>
                <HomeOutlinedIcon style={{ color: "#009197" }} />
              </ListItemIcon>
              <ListItemText primary="Home" />
            </ListItem>
          </NewLink>
          <Divider />
          {/* <NewLink
            to="/mail/"
            style={{ textDecoration: "none", color: "black" }}
          >
            <ListItem button onClick={handleDrawerClose}>
              <ListItemIcon>
                <MailOutlineIcon style={{ color: "#009197" }} />
              </ListItemIcon>
              <ListItemText primary="Mail" />
            </ListItem>
          </NewLink>
          <Divider /> */}
          {JSON.stringify(navigation_reducer.appNav).includes("Master List") ? (
            <>
              <NewLink
                to="/masterlist/"
                style={{ textDecoration: "none", color: "black" }}
              >
                <ListItem button onClick={handleDrawerClose}>
                  <ListItemIcon>
                    <GroupIcon style={{ color: "#009197" }} />
                  </ListItemIcon>
                  <ListItemText primary="Master List" />
                </ListItem>
              </NewLink>
              <Divider />
            </>
          ) : undefined}
          {JSON.stringify(navigation_reducer.appNav).includes(
            "Applicant List"
          ) ? (
            <>
              <NewLink
                to="/applicant-list/"
                style={{ textDecoration: "none", color: "black" }}
              >
                <ListItem button onClick={handleDrawerClose}>
                  <ListItemIcon>
                    <AssignmentIndIcon style={{ color: "#009197" }} />
                  </ListItemIcon>
                  <ListItemText primary="Applicant List" />
                </ListItem>
              </NewLink>
              <Divider />
            </>
          ) : undefined}
          {JSON.stringify(navigation_reducer.appNav).includes(
            "Employee Grading"
          ) ? (
            <>
              <NewLink
                to="/employee_grading/"
                style={{ textDecoration: "none", color: "black" }}
              >
                <ListItem button onClick={handleDrawerClose}>
                  <ListItemIcon>
                    <GradeIcon style={{ color: "#009197" }} />
                  </ListItemIcon>
                  <ListItemText primary="Employee Grading" />
                </ListItem>
              </NewLink>
              <Divider />
            </>
          ) : undefined}
          {JSON.stringify(navigation_reducer.appNav).includes(
            "Visitor Table"
          ) ? (
            <>
              <NewLink
                to="/visitortable/"
                style={{ textDecoration: "none", color: "black" }}
              >
                <ListItem button onClick={handleDrawerClose}>
                  <ListItemIcon>
                    <GroupIcon style={{ color: "#009197" }} />
                  </ListItemIcon>
                  <ListItemText primary="Visitor" />
                </ListItem>
              </NewLink>
              <Divider />
            </>
          ) : undefined}
          {/* {JSON.stringify(navigation_reducer.appNav).includes(
            "Employee List"
          ) ? (
            <>
              <NewLink
                to="/employeelist/"
                style={{ textDecoration: "none", color: "black" }}
              >
                <ListItem button onClick={handleDrawerClose}>
                  <ListItemIcon>
                    <PeopleOutlineIcon style={{ color: "#009197" }} />
                  </ListItemIcon>
                  <ListItemText primary="Employe List" />
                </ListItem>
              </NewLink>
              <Divider />
            </>
          ) : undefined} */}

          {state.equipments.length > 0 && (
            <>
              <ListItem button onClick={EquipmentClick}>
                <ListItemIcon>
                  <WidgetsOutlinedIcon style={{ color: "#009197" }} />
                </ListItemIcon>
                <ListItemText primary="Equipments" />
                {state.equipment ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Divider />
            </>
          )}
          {state.equipments.length > 0 && (
            <Collapse
              style={{ backgroundColor: "#d2dae2" }}
              in={state.equipment}
              timeout="auto"
              unmountOnExit
            >
              {state.equipments.map((val, index) => {
                return (
                  <NewLink
                    to={val.href}
                    style={{ textDecoration: "none", color: "black" }}
                    key={index}
                  >
                    <List component="div" disablePadding>
                      <ListItem
                        button
                        className={classes.nested}
                        onClick={handleDrawerClose}
                      >
                        <ListItemIcon>
                          {val.page_name === "Inventory" && (
                            <BallotOutlinedIcon style={{ color: "#009197" }} />
                          )}
                          {val.page_name === "Inventory Item" && (
                            <RecentActorsIcon style={{ color: "#009197" }} />
                          )}
                        </ListItemIcon>
                        <ListItemText primary={val.page_name} />
                      </ListItem>
                    </List>
                  </NewLink>
                );
              })}
            </Collapse>
          )}

          {state.vehicle_pages.length > 0 && (
            <>
              <ListItem button onClick={vehicleClick}>
                <ListItemIcon>
                  <CommuteIcon style={{ color: "#009197" }} />
                </ListItemIcon>
                <ListItemText primary="Vehicles" />
                {state.equipment ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Divider />
            </>
          )}
          {state.vehicle_pages.length > 0 && (
            <Collapse
              style={{ backgroundColor: "#d2dae2" }}
              in={state.vehicle}
              timeout="auto"
              unmountOnExit
            >
              {state.vehicle_pages.map((val, index) => {
                return (
                  <NewLink
                    to={val.href}
                    style={{ textDecoration: "none", color: "black" }}
                    key={index}
                  >
                    <List component="div" disablePadding>
                      <ListItem
                        button
                        className={classes.nested}
                        onClick={handleDrawerClose}
                      >
                        <ListItemIcon>
                          {val.page_name === "Vehicles" && (
                            <LocalShippingIcon style={{ color: "#009197" }} />
                          )}
                          {val.page_name === "Vehicle Dashboard" && (
                            <TimelineIcon style={{ color: "#009197" }} />
                          )}
                          {val.page_name === "Fuel Portal" && (
                            <LocalGasStationIcon style={{ color: "#009197" }} />
                          )}
                          {val.page_name === "Budget Portal" && (
                            <AccountBalanceWalletIcon
                              style={{ color: "#009197" }}
                            />
                          )}
                        </ListItemIcon>
                        <ListItemText primary={val.page_name} />
                      </ListItem>
                    </List>
                  </NewLink>
                );
              })}
            </Collapse>
          )}

          {state.memorandum.length > 0 && (
            <>
              <ListItem button onClick={MemoClick}>
                <ListItemIcon>
                  <RecordVoiceOverIcon style={{ color: "#009197" }} />
                </ListItemIcon>
                <ListItemText primary="NTE" />
                {state.memo ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Divider />
            </>
          )}
          {state.memorandum.length > 0 && (
            <Collapse
              style={{ backgroundColor: "#d2dae2" }}
              in={state.memo}
              timeout="auto"
              unmountOnExit
            >
              {state.memorandum.map((val, index) => {
                return (
                  <NewLink
                    to={val.href}
                    style={{ textDecoration: "none", color: "black" }}
                    key={index}
                  >
                    <List component="div" disablePadding>
                      <ListItem
                        button
                        className={classes.nested}
                        onClick={handleDrawerClose}
                      >
                        <ListItemIcon>
                          {val.page_name === "Memo" && (
                            <InsertDriveFileIcon style={{ color: "#009197" }} />
                          )}
                          {val.page_name === "NTE Absent" && (
                            <InsertDriveFileIcon style={{ color: "#009197" }} />
                          )}
                        </ListItemIcon>
                        <ListItemText primary={val.page_name} />
                      </ListItem>
                    </List>
                  </NewLink>
                );
              })}
            </Collapse>
          )}

          {state.request_logs.length > 0 && (
            <>
              <ListItem button onClick={timesheetClick}>
                <ListItemIcon>
                  <TimelapseOutlinedIcon style={{ color: "#009197" }} />
                </ListItemIcon>
                <ListItemText primary="Request Logs" />
                {state.timesheet ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Divider />
            </>
          )}

          {state.request_logs.length > 0 && (
            <Collapse
              style={{ backgroundColor: "#d2dae2" }}
              in={state.timesheet}
              timeout="auto"
              unmountOnExit
            >
              {state.request_logs.map((val, index) => {
                return (
                  <NewLink
                    to={val.href}
                    style={{ textDecoration: "none", color: "black" }}
                    key={index}
                  >
                    <List component="div" disablePadding>
                      <ListItem
                        button
                        className={classes.nested}
                        onClick={handleDrawerClose}
                      >
                        <ListItemIcon>
                          {val.page_name === "Leave Sheet" && (
                            <EventBusyOutlinedIcon
                              style={{ color: "#009197" }}
                            />
                          )}
                          {val.page_name === "Accomplishments" && (
                            <DoneAllIcon style={{ color: "#009197" }} />
                          )}

                          {val.page_name === "Overtime Sheet" && (
                            <AlarmAddOutlinedIcon
                              style={{ color: "#009197" }}
                            />
                          )}
                          {val.page_name === "Undertime Sheet" && (
                            <StarHalfOutlinedIcon
                              style={{ color: "#009197" }}
                            />
                          )}
                          {val.page_name === "OB Sheet" && (
                            <BusinessCenterOutlinedIcon
                              style={{ color: "#009197" }}
                            />
                          )}
                          {val.page_name === "Pending Requests" && (
                            <HourglassEmptyIcon style={{ color: "#009197" }} />
                          )}
                          {val.page_name === "Work From Home" && (
                            <HomeWorkIcon style={{ color: "#009197" }} />
                          )}
                          {val.page_name === "Document Sheet" && (
                            <DescriptionIcon style={{ color: "#009197" }} />
                          )}
                          {val.page_name === "Employee Appeal" && (
                            <GavelIcon style={{ color: "#009197" }} />
                          )}
                          {val.page_name === "Straight Duty" && (
                            <AlarmAddOutlinedIcon
                              style={{ color: "#009197" }}
                            />
                          )}
                          {val.page_name === "Advance Duty" && (
                            <AlarmAddOutlinedIcon
                              style={{ color: "#009197" }}
                            />
                          )}
                        </ListItemIcon>
                        <ListItemText primary={val.page_name} />
                      </ListItem>
                    </List>
                  </NewLink>
                );
              })}
            </Collapse>
          )}

          {state.attendance_pages.length > 0 && (
            <ListItem button onClick={attendanceClick}>
              <ListItemIcon>
                <HowToRegIcon style={{ color: "#009197" }} />
              </ListItemIcon>
              <ListItemText primary="Attendance" />
              {state.attendance ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
          )}
          {state.attendance_pages.length > 0 && (
            <>
              <Collapse
                style={{ backgroundColor: "#d2dae2" }}
                in={state.attendance}
                timeout="auto"
                unmountOnExit
              >
                {state.attendance_pages.map((val, index) => {
                  return (
                    <NewLink
                      to={val.href}
                      key={index}
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <List component="div" disablePadding>
                        <ListItem
                          button
                          className={classes.nested}
                          onClick={handleDrawerClose}
                        >
                          <ListItemIcon>
                            {val.page_name === "Attendance Dashboard" && (
                              <InsertChartOutlinedIcon
                                style={{ color: "#009197" }}
                              />
                            )}
                            {val.page_name === "Attendance Monitoring" && (
                              <DvrIcon style={{ color: "#009197" }} />
                            )}
                            {val.page_name === "App Logs" && (
                              <FormatListBulletedIcon
                                style={{ color: "#009197" }}
                              />
                            )}
                            {val.page_name === "Employee Sheet" && (
                              <FolderSharedOutlinedIcon
                                style={{ color: "#009197" }}
                              />
                            )}
                            {val.page_name === "Late Settings" && (
                              <SnoozeOutlinedIcon
                                style={{ color: "#009197" }}
                              />
                            )}
                            {val.page_name === "Overwrite" && (
                              <BorderColorOutlinedIcon
                                style={{ color: "#009197" }}
                              />
                            )}
                            {val.page_name === "DTR" && (
                              <FolderSharedOutlinedIcon
                                style={{ color: "#009197" }}
                              />
                            )}
                            {val.page_name === "Manual Absent" && (
                              <SupervisedUserCircleIcon
                                style={{ color: "#009197" }}
                              />
                            )}
                            {val.page_name === "Monitoring Sheet" && (
                              <DvrIcon style={{ color: "#009197" }} />
                            )}
                          </ListItemIcon>
                          <ListItemText primary={val.page_name} />
                        </ListItem>
                      </List>
                    </NewLink>
                  );
                })}
              </Collapse>
              <Divider />
            </>
          )}
          {state.upload_pages.length > 0 && (
            <ListItem button onClick={uploadClick}>
              <ListItemIcon>
                <PublishIcon style={{ color: "#009197" }} />
              </ListItemIcon>
              <ListItemText primary="Uploading" />
              {state.upload ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
          )}
          {state.upload_pages.length > 0 && (
            <>
              <Collapse
                style={{ backgroundColor: "#d2dae2" }}
                in={state.upload}
                timeout="auto"
                unmountOnExit
              >
                {state.upload_pages.map((val, index) => {
                  return (
                    <NewLink
                      to={val.href}
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <List component="div" disablePadding>
                        <ListItem
                          button
                          className={classes.nested}
                          onClick={handleDrawerClose}
                        >
                          <ListItemIcon>
                            {val.page_name === "Upload TimeSheet" && (
                              <ListAltIcon style={{ color: "#009197" }} />
                            )}
                            {val.page_name === "Upload DoubleBook" && (
                              <BookIcon style={{ color: "#009197" }} />
                            )}
                            {val.page_name === "Upload JobOrder" && (
                              <WorkOutlineIcon style={{ color: "#009197" }} />
                            )}
                            {val.page_name === "Upload Trips" && (
                              <CardTravelIcon style={{ color: "#009197" }} />
                            )}
                          </ListItemIcon>
                          <ListItemText primary={val.page_name} />
                        </ListItem>
                      </List>
                    </NewLink>
                  );
                })}
              </Collapse>
              <Divider />
            </>
          )}

          {state.payroll_pages.length > 0 && (
            <ListItem button onClick={payrollClick}>
              <ListItemIcon>
                <MonetizationOnOutlinedIcon style={{ color: "#009197" }} />
              </ListItemIcon>
              <ListItemText primary="Payroll" />
              {state.payroll ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
          )}
          {state.payroll_pages.length > 0 && (
            <>
              <Collapse
                style={{ backgroundColor: "#d2dae2" }}
                in={state.payroll}
                timeout="auto"
                unmountOnExit
              >
                {state.payroll_pages.map((val, index) => {
                  let pageName = val.page_name;
                  if (pageName === "Payroll Group") {
                    pageName = "Payroll List";
                  }
                  if (pageName === "EarningsV2") {
                    pageName = "Retro";
                  }
                  if (pageName === "DeductionsV2") {
                    pageName = "Deduction";
                  }
                  return (
                    <NewLink
                      to={val.href}
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <List component="div" disablePadding>
                        <ListItem
                          button
                          className={classes.nested}
                          onClick={handleDrawerClose}
                        >
                          <ListItemIcon>
                            {val.page_name === "EarningsV2" && (
                              <AddCircleOutlineIcon
                                style={{ color: "#009197" }}
                              />
                            )}
                            {val.page_name === "DeductionsV2" && (
                              <RemoveCircleOutlineIcon
                                style={{ color: "#009197" }}
                              />
                            )}
                            {val.page_name === "Payroll Group" && (
                              <MonetizationOnOutlinedIcon
                                style={{ color: "#009197" }}
                              />
                            )}
                            {val.page_name === "Holidays" && (
                              <BeachAccessOutlinedIcon
                                style={{ color: "#009197" }}
                              />
                            )}
                            {val.page_name === "Government Dues" && (
                              <AccountBalanceOutlinedIcon
                                style={{ color: "#009197" }}
                              />
                            )}
                            {val.page_name === "Salary Settings" && (
                              <SettingsApplicationsOutlinedIcon
                                style={{ color: "#009197" }}
                              />
                            )}
                            {val.page_name === "Payroll Requests" && (
                              <PlaylistAddCheckIcon
                                style={{ color: "#009197" }}
                              />
                            )}
                            {val.page_name === "Thirteenth Month Pay" && (
                              <DateRangeIcon style={{ color: "#009197" }} />
                            )}
                            {val.page_name === "Payroll Reports" && (
                              <DateRangeIcon style={{ color: "#009197" }} />
                            )}
                          </ListItemIcon>
                          <ListItemText primary={pageName} />
                        </ListItem>
                      </List>
                    </NewLink>
                  );
                })}

                {state.retro_pages.length > 0 && (
                  <>
                    <ListItem
                      style={{ backgroundColor: "#f1f4f6" }}
                      button
                      onClick={retroClick}
                    >
                      <ListItemIcon>
                        <ExposureIcon style={{ color: "#009197" }} />
                      </ListItemIcon>
                      <ListItemText primary="Retro" />
                      {state.retro ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Divider />
                  </>
                )}
                {state.retro_pages.length > 0 && (
                  <Collapse
                    style={{ backgroundColor: "#d2dae2" }}
                    in={state.retro}
                    timeout="auto"
                    unmountOnExit
                  >
                    {state.retro_pages.map((val, index) => {
                      return (
                        <NewLink
                          to={val.href}
                          style={{ textDecoration: "none", color: "black" }}
                        >
                          <List component="div" disablePadding>
                            <ListItem
                              button
                              className={classes.nested}
                              onClick={handleDrawerClose}
                            >
                              <ListItemIcon>
                                {val.page_name === "Retro Earnings" && (
                                  <AddBoxIcon style={{ color: "#009197" }} />
                                )}
                                {val.page_name === "Retro Deductions" && (
                                  <IndeterminateCheckBoxIcon
                                    style={{ color: "#009197" }}
                                  />
                                )}
                              </ListItemIcon>
                              <ListItemText primary={val.page_name} />
                            </ListItem>
                          </List>
                        </NewLink>
                      );
                    })}
                  </Collapse>
                )}
                {/* {state.retro_pagesv2.length > 0 && (
                  <>
                    <ListItem
                      style={{ backgroundColor: "#f1f4f6" }}
                      button
                      onClick={retroClick}
                    >
                      <ListItemIcon>
                        <ExposureIcon style={{ color: "#009197" }} />
                      </ListItemIcon>
                      <ListItemText primary="Retro" />
                      {state.retro ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Divider />
                  </>
                )} */}
                {/* {state.retro_pagesv2.length > 0 && (
                  <Collapse
                    style={{ backgroundColor: "#d2dae2" }}
                    in={state.retro}
                    timeout="auto"
                    unmountOnExit
                  >
                    {state.retro_pagesv2.map((val, index) => {
                      let pageName = "Deductions";
                      if (val.page_name === "EarningsV2") {
                        pageName = "Earnings";
                      }
                      return (
                        <NewLink
                          to={val.href}
                          style={{ textDecoration: "none", color: "black" }}
                        >
                          <List component="div" disablePadding>
                            <ListItem
                              button
                              className={classes.nested}
                              onClick={handleDrawerClose}
                            >
                              <ListItemIcon>
                                {val.page_name === "EarningsV2" && (
                                  <AddBoxIcon style={{ color: "#009197" }} />
                                )}
                                {val.page_name === "DeductionsV2" && (
                                  <IndeterminateCheckBoxIcon
                                    style={{ color: "#009197" }}
                                  />
                                )}
                              </ListItemIcon>
                              <ListItemText primary={pageName} />
                            </ListItem>
                          </List>
                        </NewLink>
                      );
                    })}
                  </Collapse>
                )} */}

                {state.payroll_adjustments_pages.length > 0 && (
                  <>
                    <ListItem
                      style={{ backgroundColor: "#f1f4f6" }}
                      button
                      onClick={adjustmentsClick}
                    >
                      <ListItemIcon>
                        <SwapVertIcon style={{ color: "#009197" }} />
                      </ListItemIcon>
                      <ListItemText primary="Adjustments" />
                      {state.adjustments ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Divider />
                  </>
                )}
                {state.payroll_adjustments_pages.length > 0 && (
                  <Collapse
                    style={{ backgroundColor: "#d2dae2" }}
                    in={state.adjustments}
                    timeout="auto"
                    unmountOnExit
                  >
                    {state.payroll_adjustments_pages.map((val, index) => {
                      return (
                        <NewLink
                          to={val.href}
                          style={{ textDecoration: "none", color: "black" }}
                        >
                          <List component="div" disablePadding>
                            <ListItem
                              button
                              className={classes.nested}
                              onClick={handleDrawerClose}
                            >
                              <ListItemIcon>
                                {val.page_name === "Additional Adjustment" && (
                                  <TrendingUpIcon
                                    style={{ color: "#009197" }}
                                  />
                                )}
                                {val.page_name === "Deduction Adjustment" && (
                                  <TrendingDownIcon
                                    style={{ color: "#009197" }}
                                  />
                                )}
                              </ListItemIcon>
                              <ListItemText primary={val.page_name} />
                            </ListItem>
                          </List>
                        </NewLink>
                      );
                    })}
                  </Collapse>
                )}
                {state.payroll_settings_pages.length > 0 && (
                  <ListItem
                    style={{ backgroundColor: "#f1f4f6" }}
                    button
                    onClick={payrollSettingsClick}
                  >
                    <ListItemIcon>
                      <TuneOutlinedIcon style={{ color: "#009197" }} />
                    </ListItemIcon>
                    <ListItemText primary="Payroll Settings" />
                    {state.payrollSettings ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                )}
                {state.payroll_settings_pages.length > 0 && (
                  <Collapse
                    style={{ backgroundColor: "#d2dae2" }}
                    in={state.payrollSettings}
                    timeout="auto"
                    unmountOnExit
                  >
                    {state.payroll_settings_pages.map((val, index) => {
                      return (
                        <NewLink
                          to={val.href}
                          style={{ textDecoration: "none", color: "black" }}
                        >
                          <List component="div" disablePadding>
                            <ListItem
                              button
                              className={classes.nested}
                              onClick={handleDrawerClose}
                            >
                              <ListItemIcon>
                                {val.page_name === "Payroll Hierarchy" && (
                                  <BeenhereOutlinedIcon
                                    style={{ color: "#009197" }}
                                  />
                                )}
                                {val.page_name === "Holiday And Wages Rate" && (
                                  <AccountBalanceWalletOutlinedIcon
                                    style={{ color: "#009197" }}
                                  />
                                )}
                                {val.page_name === "Payroll Rules" && (
                                  <PlaylistAddCheckIcon
                                    style={{ color: "#009197" }}
                                  />
                                )}
                                {val.page_name === "Payroll Rule V2" && (
                                  <PlaylistAddCheckIcon
                                    style={{ color: "#009197" }}
                                  />
                                )}
                              </ListItemIcon>
                              <ListItemText
                                primary={
                                  val.page_name === "Payroll Rule V2"
                                    ? "Payroll Batch"
                                    : val.page_name
                                }
                              />
                            </ListItem>
                          </List>
                        </NewLink>
                      );
                    })}
                  </Collapse>
                )}
              </Collapse>
              <Divider />
            </>
          )}

          {state.app_settings_pages.length > 0 && (
            <ListItem button onClick={applicationClick}>
              <ListItemIcon>
                <AppsOutlinedIcon style={{ color: "#009197" }} />
              </ListItemIcon>
              <ListItemText primary="App Settings" />
              {state.application ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
          )}
          {state.app_settings_pages.length > 0 && (
            <>
              <Collapse
                style={{ backgroundColor: "#d2dae2" }}
                in={state.application}
                timeout="auto"
                unmountOnExit
              >
                {state.app_settings_pages.map((val, index) => {
                  return (
                    <NewLink
                      to={val.href}
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <List component="div" disablePadding>
                        <ListItem
                          button
                          className={classes.nested}
                          onClick={handleDrawerClose}
                        >
                          <ListItemIcon>
                            {val.page_name === "Admin Control" && (
                              <VideogameAssetOutlinedIcon
                                style={{ color: "#009197" }}
                              />
                            )}
                          </ListItemIcon>
                          <ListItemText primary={val.page_name} />
                        </ListItem>
                      </List>
                    </NewLink>
                  );
                })}
              </Collapse>
              <Divider />
            </>
          )}
          {state.system_settings_pages.length > 0 && (
            <ListItem button onClick={systemClick}>
              <ListItemIcon>
                <TimelapseOutlinedIcon style={{ color: "#009197" }} />
              </ListItemIcon>
              <ListItemText primary="System Settings" />
              {state.system ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
          )}
          {state.system_settings_pages.length > 0 && (
            <>
              <Collapse
                style={{ backgroundColor: "#d2dae2" }}
                in={state.system}
                timeout="auto"
                unmountOnExit
              >
                {state.system_settings_pages.map((val, index) => {
                  let pageName = val.page_name;
                  if (val.page_name === "Time Table Settings") {
                    pageName = "Daily Schedule";
                  }
                  if (val.page_name === "Schedule Settings") {
                    pageName = "Fixed Schedule";
                  }
                  return (
                    <NewLink
                      to={val.href}
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <List component="div" disablePadding>
                        <ListItem
                          button
                          className={classes.nested}
                          onClick={handleDrawerClose}
                        >
                          <ListItemIcon>
                            {val.page_name === "Account Priviledge" && (
                              <VpnKeyOutlinedIcon
                                style={{ color: "#009197" }}
                              />
                            )}
                            {val.page_name === "Navigation Settings" && (
                              <NavigationOutlinedIcon
                                style={{ color: "#009197" }}
                              />
                            )}
                            {val.page_name === "Company Settings" && (
                              <BusinessOutlinedIcon
                                style={{ color: "#009197" }}
                              />
                            )}
                            {val.page_name === "Branch Settings" && (
                              <AccountTreeOutlinedIcon
                                style={{ color: "#009197" }}
                              />
                            )}
                            {val.page_name === "Department Settings" && (
                              <SettingsApplicationsOutlinedIcon
                                style={{ color: "#009197" }}
                              />
                            )}
                            {val.page_name === "Position Settings" && (
                              <WorkOutlineIcon style={{ color: "#009197" }} />
                            )}
                            {val.page_name === "Mobile Priviledge" && (
                              <PhonelinkSetupIcon
                                style={{ color: "#009197" }}
                              />
                            )}
                            {val.page_name === "Schedule Settings" && (
                              <EventNoteIcon style={{ color: "#009197" }} />
                            )}
                            {val.page_name === "Time Table Settings" && (
                              <EventIcon style={{ color: "#009197" }} />
                            )}
                            {val.page_name === "Trip Rates" && (
                              <EmojiTransportationIcon
                                style={{ color: "#009197" }}
                              />
                            )}
                            {val.page_name === "Request Hierarchy Settings" && (
                              <DeviceHubIcon style={{ color: "#009197" }} />
                            )}
                            {val.page_name === "Question Bank" && (
                              <HelpIcon style={{ color: "#009197" }} />
                            )}
                            {val.page_name === "Grading Criteria" && (
                              <HelpIcon style={{ color: "#009197" }} />
                            )}
                          </ListItemIcon>
                          <ListItemText primary={pageName} />
                        </ListItem>
                      </List>
                    </NewLink>
                  );
                })}
              </Collapse>
              <Divider />
            </>
          )}
          {JSON.stringify(navigation_reducer.appNav).includes(
            "Account Settings"
          ) ? (
            <>
              <NewLink
                to="/accountsettings/"
                style={{ textDecoration: "none", color: "black" }}
              >
                <List component="div" disablePadding>
                  <ListItem
                    button
                    className={classes.nested}
                    onClick={handleDrawerClose}
                  >
                    <ListItemIcon>
                      <SettingsApplicationsOutlinedIcon
                        style={{ color: "#009197" }}
                      />
                    </ListItemIcon>
                    <ListItemText primary="Account Settings" />
                  </ListItem>
                </List>
              </NewLink>
              <Divider />
            </>
          ) : undefined}

          {/* <a
            href="http://pockethr.gzonetechph.com/"
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "none", color: "black" }}
          >
            <ListItem button onClick={handleDrawerClose}>
              <ListItemIcon>
                <LanguageIcon style={{ color: "#009197" }} />
              </ListItemIcon>
              <ListItemText primary="POCKET HR" />
            </ListItem>
          </a> */}
          <NewLink to="/" style={{ textDecoration: "none", color: "black" }}>
            <ListItem
              button
              onClick={() => {
                logout();
              }}
            >
              <ListItemIcon>
                <ExitToAppIcon style={{ color: "#009197" }} />
              </ListItemIcon>
              <ListItemText primary="Log out" />
            </ListItem>
          </NewLink>
          <Divider />
        </List>
      </Drawer>
      <Backdrop
        className={classes.backdrop}
        open={state.open}
        onClick={handleClose}
        style={{ zIndex: 3 }}
      ></Backdrop>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleCloseDialog}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"My Profile"}

          <Button
            onClick={handleCloseDialog}
            style={{ position: "absolute", right: 15 }}
            color="primary"
          >
            Close
          </Button>
        </DialogTitle>
        <DialogContent>
          <SelfDetails />
        </DialogContent>
      </Dialog>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={ticket}
        onClose={handleCloseDialogTicket}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"CREATE TICKET"}

          <Button
            onClick={handleCloseDialogTicket}
            style={{
              position: "absolute",
              right: 15,
              color: "white",
              backgroundColor: "#009197",
            }}
          >
            Close
          </Button>
        </DialogTitle>
        <DialogContent>
          <Ticketing />
        </DialogContent>
      </Dialog>
    </>
  );
}
