const initialState = {
  darLoading: false,
  activitiy_loading: false,
  darData: [],
  requestData: [],
  requestType: "",
  userAttendance: [],
  logs: [],
  personal_details: [],
  personal_update: [],
  personalMonthlyAttendance: [],
  selectedDAR: [],
  updatedDAR: [],
  refreshRequest: false,
  refreshRequestCount: false,
  payrollRequestCount: 0,
};
const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case "DarData":
      return {
        ...state,
        darData: action.data,
      };
    case "onChangeHomeReducer":
      return {
        ...state,
        ...action.data,
      };
    case "DarLoading":
      return {
        ...state,
        darLoading: action.data,
      };
    case "activitiy_loading":
      return {
        ...state,
        activitiy_loading: action.data,
      };
    case "RequestData":
      return {
        ...state,
        requestData: action.data,
      };
    case "RequestType":
      return {
        ...state,
        requestType: action.data,
      };
    case "userAttendance":
      return {
        ...state,
        userAttendance: action.data,
      };
    case "logs":
      return {
        ...state,
        logs: action.data,
      };
    case "personal_details":
      return {
        ...state,
        personal_details: action.data,
      };
    case "personal_update":
      return {
        ...state,
        personal_details: {
          ...state.personal_details,
          ...action.data,
        },
      };
    case "personalMonthlyAttendance":
      return {
        ...state,
        personalMonthlyAttendance: action.data,
      };
    case "selectedDAR":
      return {
        ...state,
        selectedDAR: action.data,
      };
    case "updatedDAR":
      state.darData.map((val, index) => {
        if (val.accomplishment_id === action.data.accomplishment_id) {
          state.darData[index] = action.data;
        }
        return true;
      });
      return {
        ...state,
      };
    default:
      return state;
  }
};
export default dataReducer;
