const initialState = {
  vehicles: [],
  search_vehicles: [],
  sel_vehicle: [],
  search_sel_vehicles: [],
  vehicle_bar: [],
  vehicle_dashboard: [],
  search_vehicle_dashboard: [],
  gas_types: [],
  fuel_tariff: [],
};
const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case "vehicles":
      return {
        ...state,
        vehicles: action.data,
        search_vehicles: action.data,
      };
    case "search_vehicles":
      let VehicleSearch = state.vehicles.filter((files) => {
        return (
          files.vehicle_plate_no
            .toLowerCase()
            .indexOf(action.data.toLocaleLowerCase()) !== -1
        );
      });
      return {
        ...state,
        search_vehicles: VehicleSearch,
      };
    case "sel_vehicle":
      return {
        ...state,
        sel_vehicle: action.data,
        search_sel_vehicles: action.data,
      };
    case "search_sel_vehicles":
      let VehicleSelSearch = state.search_sel_vehicles.filter((files) => {
        return (
          files.req_vehicle_plate
            .toLowerCase()
            .indexOf(action.data.toLocaleLowerCase()) !== -1
        );
      });
      return {
        ...state,
        search_sel_vehicles: VehicleSelSearch,
      };
    case "vehicle_bar":
      return {
        ...state,
        vehicle_bar: action.data,
      };
    case "vehicle_dashboard":
      return {
        ...state,
        vehicle_dashboard: action.data,
        search_vehicle_dashboard: action.data,
      };
    case "search_vehicle_dashboard":
      let VehicleDashSearch = state.search_vehicle_dashboard.filter((files) => {
        return (
          files.req_vehicle_plate
            .toLowerCase()
            .indexOf(action.data.toLocaleLowerCase()) !== -1
        );
      });
      return {
        ...state,
        search_vehicle_dashboard: VehicleDashSearch,
      };
    case "gas_types":
      return {
        ...state,
        gas_types: action.data,
      };
    case "fuel_tariff":
      return {
        ...state,
        fuel_tariff: action.data,
      };
      case "added_tariff":
        return {
          ...state,
          fuel_tariff: state.fuel_tariff.concat(action.data)
        };
    default:
      return state;
  }
};
export default dataReducer;
