const initialState = {
  attendanceDetails: [],
  attendanceHeader: [],
  timesheet: [],
  searchTable: [],
  view: "",
  filter: [],
  filtable: [],
  search: [],
  resetTable: [],
  tableview: true,
  monitoring_sheet: [],
  search_monitoring_sheet: [],
  selected_present_monitoring_sheet: [],
  selected_late_monitoring_sheet: [],
  selected_absent_monitoring_sheet: [],
  selected_leave_monitoring_sheet: [],
  selected_ob_monitoring_sheet: [],
};
const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case "todayAttendance":
      return {
        ...state,
        attendanceDetails: action.data.details,
        searchTable: action.data.details,
        filtable: action.data.details,
        search: action.data.details,
      };
    case "attendanceHeader":
      return {
        ...state,
        attendanceHeader: action.data,
      };
    case "timesheet":
      return {
        ...state,
        timesheet: action.data,
      };
    case "search":
      let EmployeeSearch = state.filtable.filter((files) => {
        return (
          files.employeename
            .toLowerCase()
            .indexOf(action.data.toLocaleLowerCase()) !== -1
        );
      });
      return {
        ...state,
        search: EmployeeSearch,
      };

    case "view":
      return {
        ...state,
        view: action.data,
      };
    case "filter":
      let status = [];
      let present = "";
      state.attendanceDetails.map((val) => {
        if (
          action.status === "ALL" &&
          val.branchcompany === action.branchcompany
        ) {
          status.push(val);
        }
        if (action.status === "PRESENT") {
          if (
            (val.status === "PRESENT" || val.status === "LATE") &&
            val.branchcompany === action.branchcompany
          ) {
            status.push(val);
          }
        } else if (action.status === "LATE") {
          if (
            val.status === "LATE" &&
            val.branchcompany === action.branchcompany
          ) {
            status.push(val);
          }
        } else if (action.status === "ABSENT") {
          if (
            val.status === "ABSENT" &&
            val.branchcompany === action.branchcompany
          ) {
            status.push(val);
          }
        }
      });
      return {
        ...state,
        search: status,
        filtable: status,
      };
    case "reset":
      return {
        ...state,
        search: state.attendanceDetails,
      };
    case "tableview":
      return {
        ...state,
        tableview: action.data,
      };
    case "monitoring_sheet":
      return {
        ...state,
        monitoring_sheet: action.data,
        search_monitoring_sheet: action.data,
      };

    case "search_monitoring_sheet":
      let searchmonitoringsheet = state.monitoring_sheet.filter((files) => {
        return (
          files.name.toLowerCase().indexOf(action.data.toLocaleLowerCase()) !==
          -1
        );
      });
      return {
        ...state,
        search_monitoring_sheet: searchmonitoringsheet,
      };
    case "selected_present_monitoring_sheet":
      return {
        ...state,
        selected_present_monitoring_sheet: action.data,
      };
    case "selected_late_monitoring_sheet":
      return {
        ...state,
        selected_late_monitoring_sheet: action.data,
      };
    case "selected_absent_monitoring_sheet":
      return {
        ...state,
        selected_absent_monitoring_sheet: action.data,
      };
    case "selected_leave_monitoring_sheet":
      return {
        ...state,
        selected_leave_monitoring_sheet: action.data,
      };
    case "selected_ob_monitoring_sheet":
      return {
        ...state,
        selected_ob_monitoring_sheet: action.data,
      };
    default:
      return state;
  }
};
export default dataReducer;
