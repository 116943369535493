const initialState = {
  datasheets: [],
  handleBranch: [],
  searchTable: [],
  accomLoading: false,
  company_name: [],
  SelectedBranches: [],
  SelectedBranch: [],
  dateFrom: "",
  dateTo: "",
  accountData: [],
  singledatasheet: [],
  searchData: [],
  employeeSheets: [],
  singletimesheet: [],
  singletimesheetheader: [],
  logDetails: [],
  DAR: [],
  approvedDar: [],
  cancelDar: [],
  searchAccomplishment: [],
  searchApprovedAccomplishment: [],
  req_count: 0,
  req_approving: [],
  search_req_approving: [],
  selected_req_appr: [],
  selected_req_appr_comment: [],
  employeeSheetsV2: [],
  searchemployeeSheetsV2: [],
  downloademployeeSheetsV2: [],
  selectedemployeeSheetsV2: [],
};
const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case "employeeSheets":
      return {
        ...state,
        employeeSheets: action.data,
      };
    case "datasheets":
      return {
        ...state,
        datasheets: action.data,
      };
    case "searchData":
      return {
        ...state,
        searchData: action.data,
        searchTable: action.data,
      };
    case "gethandleBranch":
      return {
        ...state,
        handleBranch: action.data,
      };
    case "searchsheet":
      let EmployeeSearch = state.searchData.filter((files) => {
        if (files.fullname !== undefined) {
          return (
            files.fullname
              .toLowerCase()
              .indexOf(action.data.toLocaleLowerCase()) !== -1
          );
        }
      });
      return {
        ...state,
        searchTable: EmployeeSearch,
      };
    case "accomLoading":
      return {
        ...state,
        accomLoading: action.data,
      };
    case "company_name":
      return {
        ...state,
        company_name: action.data,
      };
    case "SelectedBranches":
      return {
        ...state,
        SelectedBranches: action.data,
      };
    case "SelectedBranch":
      return {
        ...state,
        SelectedBranch: state.SelectedBranches.filter(
          (val) => val.branch_id == action.data
        ),
      };
    case "dateFrom":
      return {
        ...state,
        dateFrom: action.data,
      };
    case "dateTo":
      return {
        ...state,
        dateTo: action.data,
      };
    case "accountData":
      return {
        ...state,
        accountData: action.data,
      };
    case "singledatasheet":
      return {
        ...state,
        singledatasheet: action.data,
      };
    case "singletimesheet":
      return {
        ...state,
        singletimesheet: action.data,
      };
    case "singletimesheetheader":
      return {
        ...state,
        singletimesheetheader: action.data,
      };
    case "logDetails":
      return {
        ...state,
        logDetails: action.data,
      };
    case "DAR":
      return {
        ...state,
        DAR: action.data,
        searchAccomplishment: action.data,
      };
    case "ResetAapprovedDar":
      return {
        ...state,
        approvedDar: action.data,
      };
    case "approvedDar":
      return {
        ...state,
        approvedDar: state.approvedDar.concat(action.data),
        searchApprovedAccomplishment: state.approvedDar.concat(action.data),
      };
    case "cancelDar":
      return {
        ...state,
        approvedDar: state.approvedDar.filter(
          (val) => val.accom_id != action.data.accom_id
        ),
      };
    case "afterApprove":
      let newDAR = [];
      state.DAR.map((val) => {
        let match = false;
        action.data.map((val2) => {
          if (val.accom_id === val2.accom_id) {
            match = true;
          }
        });
        if (!match) {
          newDAR.push(val);
          return true;
        }
      });
      return {
        ...state,
        DAR: newDAR,
        searchAccomplishment: newDAR,
      };
    case "searchAccomplishment":
      let AccomplishmentSearch = state.DAR.filter((files) => {
        return (
          files.complete_name
            .toLowerCase()
            .indexOf(action.data.toLocaleLowerCase()) !== -1
        );
      });
      return {
        ...state,
        searchAccomplishment: AccomplishmentSearch,
      };
    case "searchApprovedAccomplishment":
      let ApprovedAccomplishmentSearch = state.approvedDar.filter((files) => {
        return (
          files.complete_name
            .toLowerCase()
            .indexOf(action.data.toLocaleLowerCase()) !== -1
        );
      });
      return {
        ...state,
        searchApprovedAccomplishment: ApprovedAccomplishmentSearch,
      };
    case "req_count":
      return {
        ...state,
        req_count: action.data,
      };
    case "req_approving":
      return {
        ...state,
        req_approving: action.data,
        search_req_approving: action.data,
      };
    case "search_req_approving":
      let searching_request_approve = state.req_approving.filter((files) => {
        return (
          files.fullname
            .toLowerCase()
            .indexOf(action.data.toLocaleLowerCase()) !== -1 ||
          files.req_id
            .toLowerCase()
            .indexOf(action.data.toLocaleLowerCase()) !== -1
        );
      });
      return {
        ...state,
        search_req_approving: searching_request_approve,
      };
    case "selected_req_appr":
      return {
        ...state,
        selected_req_appr: action.data,
      };
    case "selected_req_appr_comment":
      return {
        ...state,
        selected_req_appr_comment: action.data,
      };
    case "OnchangeHours":
      state.req_approving.map((value) => {
        if (value.req_id === action.req_id) {
          value.req_hours_approved = action.data.req_hours_approved;
        }
      });
      return {
        ...state,
        selected_req_appr: {
          ...state.selected_req_appr,
          ...action.data,
        },
      };
    case "removeRequestonTable":
      return {
        ...state,
        req_approving: state.req_approving.filter(
          (val) => val.req_id != action.data.req_id
        ),
        search_req_approving: state.search_req_approving.filter(
          (val) => val.req_id != action.data.req_id
        ),
      };
    case "addComment":
      state.req_approving.map((value) => {
        if (value.req_id === action.req_id) {
          value.comments.push(action.data);
        }
      });
      return {
        ...state,
        // selected_req_appr_comment: state.selected_req_appr_comment.concat(action.data),
      };
    case "employeeSheetsV2":
      return {
        ...state,
        employeeSheetsV2: action.data,
        searchemployeeSheetsV2: action.data,
      };

    case "searchsheetv2":
      let EmployeeSheetV2Search = state.employeeSheetsV2.filter((files) => {
        return (
          files.COMPLETENAME.toLowerCase().indexOf(
            action.data.toLocaleLowerCase()
          ) !== -1
        );
      });
      return {
        ...state,
        searchemployeeSheetsV2: EmployeeSheetV2Search,
      };
    case "selectedemployeeSheetsV2":
      return {
        ...state,
        selectedemployeeSheetsV2: action.data,
      };
    case "downloademployeeSheetsV2":
      return {
        ...state,
        downloademployeeSheetsV2: action.data,
      };
    default:
      return state;
  }
};
export default dataReducer;
