const initialState = {
  page: 0,
  rowsPerPage: 10,
  selectedItem: [],
  total_count: 0,
  refresh: false,
  loading: false,
  pageEarning: 0,
  rowsPerPageEarning: 10,
  total_countEarning: 0,
};
const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ACTION_EARNING_DEDUCTION":
      return {
        ...state,
        ...action.data,
      };
    default:
      return state;
  }
};
export default dataReducer;
