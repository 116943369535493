const initialState = {
  getUsers: [],
  getEmployeeList: [],
  selectedEmployee: [],
  filtertable: [],
  searchTable: [],
  handleBranch: [],
  company_name: [],
  SelectedBranches: [],
  SelectedBranch: [],
  position: [],
  updateEmployee: [],
  employeeRequests: [],
  selectedRequest: [],
  requestLoading: false,
  selected_emp_sched: [],
  masterLoading: false,
  createdHierarchy: [],
  createdHierarchyLoading: false,
  createdHierarchyTypes: [],
  user_id: "",
  EmployeeAccomplishment: [],
  selectedAccomplishment: [],
  masterdownload: [],
};
const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case "getUsers":
      return {
        ...state,
        getUsers: action.data,
        filtertable: action.data.users,
        getEmployeeList: action.data.users,
        searchTable: action.data.users,
      };
    case "EmployeeAccomplishment":
      return {
        ...state,
        EmployeeAccomplishment: action.data,
      };
    case "selectedEmployee":
      return {
        ...state,
        selectedEmployee: action.data,
      };
    case "updateEmployee":
      return {
        ...state,
        selectedEmployee: {
          ...state.selectedEmployee,
          ...action.data,
        },
      };
    case "filtering":
      let EmployeeFilter = [];
      if (action.data.company_id === "All") {
        EmployeeFilter = state.getEmployeeList;
        // || action.data.branch_id === 'All'
      } else {
        if (action.data.branch_id === "All") {
          EmployeeFilter = state.getEmployeeList.filter(
            (files) => files.company_id === action.data.company_id
          );
        } else {
          EmployeeFilter = state.getEmployeeList.filter(
            (files) => files.branch_id === action.data.branch_id
          );
        }
      }
      return {
        ...state,
        searchTable: EmployeeFilter,
        filtertable: EmployeeFilter,
      };
    case "search":
      let EmployeeSearch = state.filtertable.filter((files) => {
        return (
          files.completename
            .toLowerCase()
            .indexOf(action.data.toLocaleLowerCase()) !== -1 ||
          files.user_id
            .toLowerCase()
            .indexOf(action.data.toLocaleLowerCase()) !== -1
        );
      });
      return {
        ...state,
        searchTable: EmployeeSearch,
      };
    case "handleBranch":
      return {
        ...state,
        handleBranch: action.data,
      };
    case "company_name":
      return {
        ...state,
        company_name: action.data,
      };
    case "SelectedBranches":
      return {
        ...state,
        SelectedBranches: action.data,
      };
    case "SelectedBranch":
      return {
        ...state,
        SelectedBranch: state.SelectedBranches.filter(
          (val) => val.branch_id == action.data
        ),
      };
    case "position":
      return {
        ...state,
        position: action.data,
      };
    case "addedEmployee":
      return {
        ...state,
        filtertable: state.filtertable.concat(action.data),
        searchTable: state.searchTable.concat(action.data),
        getEmployeeList: state.getEmployeeList.concat(action.data),
      };
    case "getDepartments":
      return {
        ...state,
        getDepartments: action.data,
      };
    case "updatedEmployee":
      state.searchTable.map((val) => {
        if (val.user_id === action.data.user_id) {
          val.allowance = action.data.allowance;
          val.completename =
            action.data.user_lname +
            " " +
            action.data.user_fname +
            " " +
            action.data.user_mname;
          val.company_id = action.data.company_id;
          val.company_name = action.data.company_name;
          val.user_mobile = action.data.user_mobile;
          val.user_lname = action.data.user_lname;
          val.user_fname = action.data.user_fname;
          val.user_mname = action.data.user_mname;
          val.user_civilstatus = action.data.user_civilstatus;
          val.user_address = action.data.user_address;
          val.user_gender = action.data.user_gender;
          val.user_date_added = action.data.user_date_added;
          val.user_email = action.data.user_email;
          val.user_sname = action.data.user_sname;
          val.account_username = action.data.account_username;
          val.branch_name = action.data.branch_name;
          val.salary_rate = action.data.salary_rate;
          val.type_rate = action.data.type_rate;
          val.branch_id = action.data.branch_id;
          val.user_request_hierarchy = action.data.user_request_hierarchy;
          val.user_device_id = action.data.user_device_id;
          val.user_DAR_hierarchy = action.data.user_DAR_hierarchy;
          val.user_pic = action.data.user_pic;
          val.user_assign_area = action.data.user_assign_area;
          val.category_id = action.data.category_id;
          val.category_details = action.data.category_details;
          val.user_quota = action.data.user_quota;
          val.user_quota_type = action.data.user_quota_type;
          val.unit = action.data.unit;
          val.byte = action.data.byte;
          val.dept_name = action.data.dept_name;
          val.user_leave = action.data.user_leave;
          val.sss = action.data.sss;
          val.philHealth = action.data.philHealth;
          val.tin = action.data.tin;
          val.pagIbig = action.data.pagIbig;
          val.valucare = action.data.valucare;
          val.job_description = action.data.job_description;
          val.dept_id = action.data.dept_id;
          val.private = action.data.private;
          val.user_jobposition = action.data.user_jobposition;
          val.late_settings = action.data.late_settings;
          val.user_status = action.data.user_status;
          val.user_bday = action.data.user_bday;
          val.employee_status = action.data.employee_status;
          val.user_employment_status = action.data.user_employment_status;
          val.employee_activity = action.data.employee_activity;
          val.birthplace = action.data.birthplace;
          val.contact_relation = action.data.contact_relation;
          val.contact_number = action.data.contact_number;
          val.contact_name = action.data.contact_name;
          val.contact_address = action.data.contact_address;
          val.user_age = action.data.user_age;
          val.user_gcash = action.data.user_gcash;
          val.user_telephone = action.data.user_telephone;
          val.pres_house_street_subd = action.data.pres_house_street_subd;
          val.pres_barangay = action.data.pres_barangay;
          val.pres_city = action.data.pres_city;
          val.pres_province = action.data.pres_province;
          val.pres_country = action.data.pres_country;
          val.pres_zip = action.data.pres_zip;
          val.perm_house_street_subd = action.data.perm_house_street_subd;
          val.perm_barangay = action.data.perm_barangay;
          val.perm_city = action.data.perm_city;
          val.perm_province = action.data.perm_province;
          val.perm_country = action.data.perm_country;
          val.perm_zip = action.data.perm_zip;
          val.user_date_hired = action.data.user_date_hired;
          val.barcode_id = action.data.barcode_id;
          val.branch_company = action.data.branch_company;
          val.complete_present_address = action.data.complete_present_address;
          val.complete_permanent_address =
            action.data.complete_permanent_address;
          val.user_date_resigned = action.data.user_date_resigned;
        }
      });
      state.getEmployeeList.map((val) => {
        if (val.user_id === action.data.user_id) {
          val.allowance = action.data.allowance;
          val.completename =
            action.data.user_lname +
            " " +
            action.data.user_fname +
            " " +
            action.data.user_mname;
          val.company_id = action.data.company_id;
          val.company_name = action.data.company_name;
          val.user_mobile = action.data.user_mobile;
          val.user_lname = action.data.user_lname;
          val.user_fname = action.data.user_fname;
          val.user_mname = action.data.user_mname;
          val.user_civilstatus = action.data.user_civilstatus;
          val.user_address = action.data.user_address;
          val.user_gender = action.data.user_gender;
          val.user_date_added = action.data.user_date_added;
          val.user_email = action.data.user_email;
          val.user_sname = action.data.user_sname;
          val.account_username = action.data.account_username;
          val.branch_name = action.data.branch_name;
          val.salary_rate = action.data.salary_rate;
          val.type_rate = action.data.type_rate;
          val.branch_id = action.data.branch_id;
          val.user_request_hierarchy = action.data.user_request_hierarchy;
          val.user_device_id = action.data.user_device_id;
          val.user_DAR_hierarchy = action.data.user_DAR_hierarchy;
          val.user_pic = action.data.user_pic;
          val.user_assign_area = action.data.user_assign_area;
          val.category_id = action.data.category_id;
          val.category_details = action.data.category_details;
          val.user_quota = action.data.user_quota;
          val.user_quota_type = action.data.user_quota_type;
          val.unit = action.data.unit;
          val.byte = action.data.byte;
          val.dept_name = action.data.dept_name;
          val.user_leave = action.data.user_leave;
          val.sss = action.data.sss;
          val.philHealth = action.data.philHealth;
          val.tin = action.data.tin;
          val.pagIbig = action.data.pagIbig;
          val.valucare = action.data.valucare;
          val.job_description = action.data.job_description;
          val.dept_id = action.data.dept_id;
          val.private = action.data.private;
          val.user_jobposition = action.data.user_jobposition;
          val.late_settings = action.data.late_settings;
          val.user_status = action.data.user_status;
          val.user_bday = action.data.user_bday;
          val.employee_status = action.data.employee_status;
          val.user_employment_status = action.data.user_employment_status;
          val.employee_activity = action.data.employee_activity;
          val.birthplace = action.data.birthplace;
          val.contact_relation = action.data.contact_relation;
          val.contact_number = action.data.contact_number;
          val.contact_name = action.data.contact_name;
          val.contact_address = action.data.contact_address;
          val.user_age = action.data.user_age;
          val.user_gcash = action.data.user_gcash;
          val.user_telephone = action.data.user_telephone;
          val.pres_house_street_subd = action.data.pres_house_street_subd;
          val.pres_barangay = action.data.pres_barangay;
          val.pres_city = action.data.pres_city;
          val.pres_province = action.data.pres_province;
          val.pres_country = action.data.pres_country;
          val.pres_zip = action.data.pres_zip;
          val.perm_house_street_subd = action.data.perm_house_street_subd;
          val.perm_barangay = action.data.perm_barangay;
          val.perm_city = action.data.perm_city;
          val.perm_province = action.data.perm_province;
          val.perm_country = action.data.perm_country;
          val.perm_zip = action.data.perm_zip;
          val.user_date_hired = action.data.user_date_hired;
          val.barcode_id = action.data.barcode_id;
          val.branch_company = action.data.branch_company;
          val.complete_present_address = action.data.complete_present_address;
          val.complete_permanent_address =
            action.data.complete_permanent_address;
          val.user_date_resigned = action.data.user_date_resigned;
        }
      });
      state.filtertable.map((val) => {
        if (val.user_id === action.data.user_id) {
          val.allowance = action.data.allowance;
          val.completename =
            action.data.user_lname +
            " " +
            action.data.user_fname +
            " " +
            action.data.user_mname;
          val.company_id = action.data.company_id;
          val.company_name = action.data.company_name;
          val.user_mobile = action.data.user_mobile;
          val.user_lname = action.data.user_lname;
          val.user_fname = action.data.user_fname;
          val.user_mname = action.data.user_mname;
          val.user_civilstatus = action.data.user_civilstatus;
          val.user_address = action.data.user_address;
          val.user_gender = action.data.user_gender;
          val.user_date_added = action.data.user_date_added;
          val.user_email = action.data.user_email;
          val.user_sname = action.data.user_sname;
          val.account_username = action.data.account_username;
          val.branch_name = action.data.branch_name;
          val.salary_rate = action.data.salary_rate;
          val.type_rate = action.data.type_rate;
          val.branch_id = action.data.branch_id;
          val.user_request_hierarchy = action.data.user_request_hierarchy;
          val.user_device_id = action.data.user_device_id;
          val.user_DAR_hierarchy = action.data.user_DAR_hierarchy;
          val.user_pic = action.data.user_pic;
          val.user_assign_area = action.data.user_assign_area;
          val.category_id = action.data.category_id;
          val.category_details = action.data.category_details;
          val.user_quota = action.data.user_quota;
          val.user_quota_type = action.data.user_quota_type;
          val.unit = action.data.unit;
          val.byte = action.data.byte;
          val.dept_name = action.data.dept_name;
          val.user_leave = action.data.user_leave;
          val.sss = action.data.sss;
          val.philHealth = action.data.philHealth;
          val.tin = action.data.tin;
          val.pagIbig = action.data.pagIbig;
          val.valucare = action.data.valucare;
          val.job_description = action.data.job_description;
          val.dept_id = action.data.dept_id;
          val.private = action.data.private;
          val.user_jobposition = action.data.user_jobposition;
          val.late_settings = action.data.late_settings;
          val.user_status = action.data.user_status;
          val.user_bday = action.data.user_bday;
          val.employee_status = action.data.employee_status;
          val.user_employment_status = action.data.user_employment_status;
          val.employee_activity = action.data.employee_activity;
          val.birthplace = action.data.birthplace;
          val.contact_relation = action.data.contact_relation;
          val.contact_number = action.data.contact_number;
          val.contact_name = action.data.contact_name;
          val.contact_address = action.data.contact_address;
          val.user_age = action.data.user_age;
          val.user_gcash = action.data.user_gcash;
          val.user_telephone = action.data.user_telephone;
          val.pres_house_street_subd = action.data.pres_house_street_subd;
          val.pres_barangay = action.data.pres_barangay;
          val.pres_city = action.data.pres_city;
          val.pres_province = action.data.pres_province;
          val.pres_country = action.data.pres_country;
          val.pres_zip = action.data.pres_zip;
          val.perm_house_street_subd = action.data.perm_house_street_subd;
          val.perm_barangay = action.data.perm_barangay;
          val.perm_city = action.data.perm_city;
          val.perm_province = action.data.perm_province;
          val.perm_country = action.data.perm_country;
          val.perm_zip = action.data.perm_zip;
          val.user_date_hired = action.data.user_date_hired;
          val.barcode_id = action.data.barcode_id;
          val.branch_company = action.data.branch_company;
          val.complete_present_address = action.data.complete_present_address;
          val.complete_permanent_address =
            action.data.complete_permanent_address;
          val.user_date_resigned = action.data.user_date_resigned;
        }
      });
      return {
        ...state,
      };
    case "delete_employee":
      return {
        ...state,
        getEmployeeList: state.getEmployeeList.filter(
          (val) => val.user_id != action.data.user_id
        ),
        searchTable: state.searchTable.filter(
          (val) => val.user_id != action.data.user_id
        ),
      };
    case "employeeRequests":
      return {
        ...state,
        employeeRequests: action.data,
      };
    case "selectedRequest":
      return {
        ...state,
        selectedRequest: action.data,
      };
    case "selectedAccomplishment":
      return {
        ...state,
        selectedAccomplishment: action.data,
      };
    case "requestLoading":
      return {
        ...state,
        requestLoading: action.data,
      };
    case "selected_emp_sched":
      return {
        ...state,
        selected_emp_sched: action.data,
      };
    case "onChangeTextConti":
      return {
        ...state,
        selectedEmployee: {
          ...state.selectedEmployee,
          ...action.data,
        },
      };
    case "updateContiUser":
      return {
        ...state,
        selectedEmployee: {
          ...state.selectedEmployee,
          ...action.data,
        },
      };
    case "masterLoading":
      return {
        ...state,
        masterLoading: action.data,
      };
    case "createdHierarchy":
      return {
        ...state,
        createdHierarchy: action.data,
      };
    case "createdHierarchyLoading":
      return {
        ...state,
        createdHierarchyLoading: action.data,
      };
    case "createdHierarchyTypes":
      return {
        ...state,
        createdHierarchyTypes: action.data,
      };
    case "updatingHierarchy1":
      state.getEmployeeList.map((value) => {
        if (value.user_id === action.user_id) {
          value.user_request_hierarchy = action.data.user_request_hierarchy;
        }
        return true;
      });
      state.searchTable.map((value) => {
        if (value.user_id === action.user_id) {
          value.user_request_hierarchy = action.data.user_request_hierarchy;
        }
        return true;
      });
      return {
        ...state,
      };

    case "updatingHierarchy2":
      return {
        ...state,
        selectedEmployee: {
          ...state.selectedEmployee,
          ...action.data,
        },
      };
    case "onChangeDP":
      state.getEmployeeList.map((value) => {
        if (value.user_id === action.user_id) {
          value.user_pic = action.data.user_pic;
        }
        return true;
      });
      return {
        ...state,
        selectedEmployee: {
          ...state.selectedEmployee,
          ...action.data,
        },
      };
    case "onChangeBarcode":
      state.getEmployeeList.map((value) => {
        if (value.user_id === action.user_id) {
          value.barcode_id = action.data.barcode_id;
        }
        return true;
      });
      return {
        ...state,
        selectedEmployee: {
          ...state.selectedEmployee,
          ...action.data,
        },
      };
    case "onChangeCola":
      state.getEmployeeList.map((value) => {
        if (value.user_id === action.user_id) {
          value.cola = action.data.cola;
        }
        return true;
      });
      return {
        ...state,
        selectedEmployee: {
          ...state.selectedEmployee,
          ...action.data,
        },
      };
    case "onChangeTranspo":
      state.getEmployeeList.map((value) => {
        if (value.user_id === action.user_id) {
          value.transpo_allowance = action.data.transpo_allowance;
        }
        return true;
      });
      return {
        ...state,
        selectedEmployee: {
          ...state.selectedEmployee,
          ...action.data,
        },
      };
    case "masterdownload":
      return {
        ...state,
        masterdownload: action.data,
      };
    default:
      return state;
  }
};
export default dataReducer;
