import { getData } from "../../api/api";
import moment from "moment";

export function getJD() {
  return new Promise((resolve, reject) => {
    getData("position/getJD").then((response) => {
      resolve(response);
    });
  });
}

export function getUserLoginData() {
  return new Promise((resolve, reject) => {
    getData("users/getPrivilege2", localStorage.getItem("u")).then(
      (response) => {
        resolve(response);
      }
    );
  });
}

export function insertDar(darDetails) {
  return new Promise((resolve, reject) => {
    getData("HumanResource/LogAccomplishment", darDetails).then((response) => {
      resolve(response);
    });
  });
}

export function insertDarv2(darDetails) {
  return new Promise((resolve, reject) => {
    getData("HumanResource/LogAccomplishmentv2", darDetails).then(
      (response) => {
        resolve(response);
      }
    );
  });
}

export function getLoginDar(data) {
  return new Promise((resolve, reject) => {
    getData("users/getLoginUserDar", data).then((response) => {
      resolve(response.data);
    });
  });
}

function createData(
  id,
  accomplishment,
  type,
  status,
  timeState,
  timeEnd,
  dateFiled
) {
  return { id, accomplishment, type, status, timeState, timeEnd, dateFiled };
}

export function insertRequest(data) {
  return new Promise((resolve, reject) => {
    getData("HumanResource/logRequest", data).then((response) => {
      resolve(response);
    });
  });
}

export function getRequest(data) {
  return new Promise((resolve, reject) => {
    getData("HumanResource/getRequestData", data).then((response) => {
      let requestDetails = response.request.map((val) =>
        createDataRequest(
          val.req_id,
          moment(val.req_date_start).format("LL"),
          moment(val.req_date_start).format("LT"),
          moment(val.req_date_end).format("LT"),
          val.req_type,
          val.req_justification,
          val.req_hours_approved,
          val.status
        )
      );
      resolve(requestDetails);
    });
  });
}
function createDataRequest(
  id,
  date,
  start,
  end,
  type,
  justification,
  approved_hours,
  status
) {
  return { id, date, start, end, type, justification, approved_hours, status };
}

export function getUserLoginAttendance(darDetails) {
  return new Promise((resolve, reject) => {
    getData("users/getLoginUserTimesheet", darDetails).then((response) => {
      resolve(response);
    });
  });
}

export function getVehicleDetails(data) {
  return new Promise((resolve, reject) => {
    getData("users/getVehicleDetails", data).then((response) => {
      resolve(response);
    });
  });
}
export function getPresentUserAttendance(data) {
  return new Promise((resolve, reject) => {
    getData("HumanResource/getNewsFedd", data).then((response) => {
      // resolve.response
    });
  });
}
export function getHandleBranch(data) {
  return new Promise((resolve, reject) => {
    getData("HumanResource/getHandleBranch", data).then((response) => {
      resolve(response);
    });
  });
}
export function getUserDetailsbyID(data) {
  return new Promise((resolve, reject) => {
    getData("HumanResource/getUserDetailsbyID", data).then((response) => {
      resolve(response);
    });
  });
}
export function updateEmployee(data) {
  return new Promise((resolve, reject) => {
    getData("users/updatefieldman", data).then((response) => {
      resolve(response);
    });
  });
}
export function updatingEmployee(data) {
  return new Promise((resolve, reject) => {
    getData("users/updatingemployee", data).then((response) => {
      resolve(response);
    });
  });
}
export function getCompanies(data) {
  return new Promise((resolve, reject) => {
    getData("users/Get_users", data).then((response) => {
      resolve(response.companies);
    });
  });
}
export function addCompany(data) {
  return new Promise((resolve, reject) => {
    getData("users/addCompany", data).then((response) => {
      resolve(response);
    });
  });
}
export function updateCompany(data) {
  return new Promise((resolve, reject) => {
    getData("users/updateCompany", data).then((response) => {
      resolve(response);
    });
  });
}
export function deleteCompany(data) {
  return new Promise((resolve, reject) => {
    getData("users/deleteCompany", data).then((response) => {
      resolve(response);
    });
  });
}
export function getSelftMonthlyAttendance(data) {
  return new Promise((resolve, reject) => {
    getData("humanResource/getMonthAttendance", data).then((response) => {
      resolve(response.DaysPresent);
    });
  });
}
export function createSchedule(data) {
  return new Promise((resolve, reject) => {
    getData("users/updateSched", data).then((response) => {
      resolve(response);
    });
  });
}
export function fetchCreatedSchedule(data) {
  return new Promise((resolve, reject) => {
    getData("users/fetchCreatedSchedule", data).then((response) => {
      resolve(response);
    });
  });
}
export function getSchedulebyID(data) {
  return new Promise((resolve, reject) => {
    getData("users/getSchedulebyID", data).then((response) => {
      resolve(response);
    });
  });
}
export function onUpdateUsersSched(data) {
  return new Promise((resolve, reject) => {
    getData("users/onUpdateUsersSched", data).then((response) => {
      resolve(response);
    });
  });
}
export function deleteDAR(data) {
  return new Promise((resolve, reject) => {
    getData("humanresource/updateAccomplishments", data).then((response) => {
      resolve(response);
    });
  });
}
export function fetchAccountTypes() {
  return new Promise((resolve, reject) => {
    getData("users/fetchAccountType").then((response) => {
      resolve(response);
    });
  });
}
export function getScheduleLogs(data) {
  return new Promise((resolve, reject) => {
    getData("users/getScheduleLogs", data).then((response) => {
      resolve(response);
    });
  });
}
export function getSingleLogs(data) {
  return new Promise((resolve, reject) => {
    getData("users/getSingleLogs", data).then((response) => {
      resolve(response);
    });
  });
}
export function deleteSchedlogs(data) {
  return new Promise((resolve, reject) => {
    getData("users/deleteSchedlogs", data).then((response) => {
      resolve(response);
    });
  });
}
export function getApprovingRequest(data) {
  return new Promise((resolve, reject) => {
    getData("humanresource/getRequest", data).then((response) => {
      resolve(response);
    });
  });
}

export function getAllHandleBranch(data) {
  return new Promise((resolve, reject) => {
    getData("users/Get_users", data).then((response) => {
      resolve(response.branches);
    });
  });
}
export function getAccountCategories(data) {
  return new Promise((resolve, reject) => {
    getData("auth/getAccountCategories", data).then((response) => {
      resolve(response);
    });
  });
}
export function LogMultiReq(data) {
  return new Promise((resolve, reject) => {
    getData("HumanResource/logmultiplerequest", data).then((response) => {
      resolve(response);
    });
  });
}
export function AllReqbyType(data) {
  return new Promise((resolve, reject) => {
    getData("HumanResource/getAllRequestbyDate", data).then((response) => {
      resolve(response);
    });
  });
}
export function getPosition() {
  return new Promise((resolve, reject) => {
    getData("position/getPositionbyID").then((response) => {
      resolve(response);
    });
  });
}
export function addQuestion(data) {
  return new Promise((resolve, reject) => {
    getData("jmm/addQuestion", data).then((response) => {
      resolve(response);
    });
  });
}
export function fetchQuestion() {
  return new Promise((resolve, reject) => {
    getData("jmm/fetchQuestions").then((response) => {
      resolve(response);
    });
  });
}
export function DeleteQuestion(data) {
  return new Promise((resolve, reject) => {
    getData("jmm/deletingQuestion", data).then((response) => {
      resolve(response);
    });
  });
}
export function AddPosition(data) {
  return new Promise((resolve, reject) => {
    getData("position/addPositionbyID", data).then((response) => {
      resolve(response);
    });
  });
}

export function EditPosition(data) {
  return new Promise((resolve, reject) => {
    getData("position/updatePosition", data).then((response) => {
      resolve(response);
    });
  });
}
export function DeletePosition(data) {
  return new Promise((resolve, reject) => {
    getData("position/deletePosition", data).then((response) => {
      resolve(response);
    });
  });
}
export function applicantMentalTests(data) {
  return new Promise((resolve, reject) => {
    getData("jmm/applicantMentalTest", data).then((response) => {
      resolve(response);
    });
  });
}
export function applicantMathTests(data) {
  return new Promise((resolve, reject) => {
    getData("jmm/applicantMathTest", data).then((response) => {
      resolve(response);
    });
  });
}
export function DriverMultiTests(data) {
  return new Promise((resolve, reject) => {
    getData("jmm/applicantDriverTest", data).then((response) => {
      resolve(response);
    });
  });
}
export function driverEssay(data) {
  return new Promise((resolve, reject) => {
    getData("jmm/driverEssay", data).then((response) => {
      resolve(response);
    });
  });
}
export function fetchingQuestions() {
  return new Promise((resolve, reject) => {
    getData("jmm/fetchQuestions").then((response) => {
      resolve(response);
    });
  });
}
export function fetchQuestionsbyType(data) {
  return new Promise((resolve, reject) => {
    getData("jmm/fetchQuestionsbyType", data).then((response) => {
      resolve(response);
    });
  });
}

export function InsertCategory(data) {
  return new Promise((resolve, reject) => {
    getData("HumanResource/InsertCategory", data).then((response) => {
      resolve(response);
    });
  });
}
export function UpdateCategoryDetails(data) {
  return new Promise((resolve, reject) => {
    getData("HumanResource/UpdateCategoryDetails", data).then((response) => {
      resolve(response);
    });
  });
}
export function GetCategoryTypeDetails(data) {
  return new Promise((resolve, reject) => {
    getData("HumanResource/GetCategoryTypeDetails", data).then((response) => {
      resolve(response);
    });
  });
}
export function getEvaluationMonthEmployees(data) {
  return new Promise((resolve, reject) => {
    getData("HumanResource/getEvaluationListMonth", data).then((response) => {
      resolve(response);
    });
  });
}
export function LogEvaluation(data) {
  return new Promise((resolve, reject) => {
    getData("tracking/LogEvaluationGrade", data).then((response) => {
      resolve(response);
    });
  });
}

export function GetTicketCategories() {
  return new Promise((resolve, reject) => {
    getData("helpdesk/getHelpCategories").then((response) => {
      resolve(response);
    });
  });
}
export function getUserMonthlyAttendance(data) {
  return new Promise((resolve, reject) => {
    getData("humanResource/getAttendanceMonthDetails", data).then(
      (response) => {
        resolve(response);
      }
    );
  });
}
export function addBranch(data) {
  return new Promise((resolve, reject) => {
    getData("users/addBranch", data).then((response) => {
      resolve(response);
    });
  });
}
export function updateBranch(data) {
  return new Promise((resolve, reject) => {
    getData("users/updateBranch", data).then((response) => {
      resolve(response);
    });
  });
}
export function deleteBranch(data) {
  return new Promise((resolve, reject) => {
    getData("users/deleteBranch", data).then((response) => {
      resolve(response);
    });
  });
}
export function addDepartment(data) {
  return new Promise((resolve, reject) => {
    getData("users/addDepartment", data).then((response) => {
      resolve(response);
    });
  });
}
export function updateDepartment(data) {
  return new Promise((resolve, reject) => {
    getData("users/updateDepartment", data).then((response) => {
      resolve(response);
    });
  });
}
export function deleteDepartment(data) {
  return new Promise((resolve, reject) => {
    getData("users/deleteDepartment", data).then((response) => {
      resolve(response);
    });
  });
}
export function getAllHierarchyApprover(data) {
  return new Promise((resolve, reject) => {
    getData("humanResource/getAllHierarchyApprover", data).then((response) => {
      resolve(response);
    });
  });
}
