export const serverimagesprofile =
  "http://images.workflow.gzonetechph.com/pockethr/profilepic/";
export const serverapplicantimagesprofile =
  "http://images.workflow.gzonetechph.com/pockethr/applicant_pics/";
export const serverapplicantdocs =
  "http://images.workflow.gzonetechph.com/pockethr/applicant_docs/";
export const serverimagessigniture =
  "https://api.workflow.gzonetechph.com/helpdesk/uploadSignitureFile/";
export const serverimagesuploaddp =
  "https://api.workflow.gzonetechph.com/helpdesk/uploadSingleFile/";
export const websitelink = "https://pockethr.gzontechph.com.ph/#/";
export const betawebsitelink = "https://api.pockethr.gzontechph.com.ph/#/";
export const betaserverimagesuploaddp =
  "https://api.workflow.gzonetechph.com/jmm/uploadUserImg/";
