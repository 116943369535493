import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
function Create_help() {
//   const ticket_reducer = useSelector((state) => state.Ticket);
  const navigation_reducer = useSelector((state) => state.Navigation);
  const dispatch = useDispatch();
  const dispatch_data = (type, data) => {
    dispatch({
      type: type,
      data: data,
    });
  };
  const [state, setState] = React.useState({
    h_subject: "",
    h_type: "",
    h_description: "",
    h_upload: [],
  });
  const onChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
  };
  const onChangeImage = (event) => {
    setState({
      ...state,
      h_upload: event.target.files,
    });
  };
  const onSubmit = (e) => {
    e.preventDefault();
    let data = new FormData();
    if (state.h_upload.length === 0) {
      data.append("file[]", []);
    } else {
      for (let x = 0; x < state.h_upload.length; x++) {
        data.append("file[]", state.h_upload[x]);
      }
    }
    data.set("subject", state.h_subject);
    data.set("type", state.h_type);
    data.set("description", state.h_description);
    data.set("branch_id", navigation_reducer.userLoginData[0].branch_id);
    data.set("user", localStorage.getItem("u"));

    axios({
      method: "post",
      url: "http://api.workflow.gzonetechph.com/helpdesk/submitHelp/",
      data: data,
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
      },
    }).then((response) => {
      if (response.statusText === "OK") {
        alert('Ticket submitted successfully')
      } else {
        alert('Failed to submit ticket. Please reload the page and try again')
      }
    });
  };
  return (
    <form onSubmit={onSubmit}>
      <Grid container spacing={2}>
        <Grid item sm={12}>
          <TextField
            onChange={onChange}
            label="Subject: "
            variant="outlined"
            size="small"
            required
            name="h_subject"
            style={{ width: "100%" }}
            helperText="Brief summary of the question or issue."
          />
        </Grid>
        <Grid item sm={12}>
          <FormControl
            variant="outlined"
            style={{ width: "100%" }}
            size="small"
          >
            <InputLabel id="demo-simple-select-outlined-label">
              Type:
            </InputLabel>

            <Select defaultValue = "" onChange={onChange} required name="h_type">
              <MenuItem value='None'>
                <em>None</em>
              </MenuItem>
              {navigation_reducer.ticket_categories.map((val) => {
                return (
                  <MenuItem key={val.category_id} value={val.category_details}>
                    {val.category_details}
                  </MenuItem>
                );
              })}
            </Select>
            <FormHelperText>Summary of the question or issue.</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item sm={12}>
          <TextField
            onChange={onChangeImage}
            type="file"
            variant="outlined"
            size="small"
            name="h_upload"
            style={{ width: "100%" }}
            helperText="Upload screenshot. (Not Required)"
          />
        </Grid>
        <Grid item sm={12}>
          <FormControl
            variant="outlined"
            style={{ width: "100%" }}
            size="small"
          >
            <TextareaAutosize
              minRows={3}
              onChange={onChange}
              required
              placeholder="Description..."
              variant="outlined"
              name="h_description"
            />
            <FormHelperText>Summary of the question or issue.</FormHelperText>
          </FormControl>
        </Grid>
        <Grid
          item
          sm={12}
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Button
            type="submit"
            variant="contained"
            style={{ color: "white", backgroundColor: "#009197" }}
          >
            Create
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}

export default Create_help;