import { ACTION_PAYROLL_REQUESTS } from "../../action/actionPayrollRequests/ActionTypes";
const initialState = {
  page: 0,
  rowsPerPage: 10,
  selectedPayrollGroup: [],
  total_count: 0,
};
const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_PAYROLL_REQUESTS:
      return {
        ...state,
        ...action.data,
      };
    default:
      return state;
  }
};
export default dataReducer;
