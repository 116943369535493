import { combineReducers } from "redux";
import Navigation from "../reducer/navigation_reducer";
import Home from "../reducer/home_reducer";
import EmployeeList from "../reducer/employee_list_reducer";
import Attendance from "../reducer/attendance_reducer";
import Payroll from "../reducer/payroll_reducer";
import Sheet from "../reducer/sheet_reducer";
import AttendMonitoring from "../reducer/attendance_monitoring";
import System from "../reducer/system_reducer";
import ThirteenMonth from "../reducer/thirteenMonth_reducer";
import Inventory from "../reducer/inventory_reducer";
import Vehicle from "../reducer/vehicle_reducer";
import Applicant from "../reducer/applicant_reducer";
import PayrollRequest from "../reducer/payroll_request_payroll";
import EarningdDeduction from "../reducer/earning_deduction_reduser";
import MasterList from "../reducer/master_list_reducer";
import userManagementReducer from "../reducer/usermanagement_reducer";
export default combineReducers({
  Navigation: Navigation,
  Home: Home,
  EmployeeList: EmployeeList,
  Attendance: Attendance,
  Payroll: Payroll,
  Sheet: Sheet,
  AttendMonitoring: AttendMonitoring,
  System: System,
  ThirteenMonth: ThirteenMonth,
  Inventory: Inventory,
  Vehicle: Vehicle,
  Applicant: Applicant,
  PayrollRequest: PayrollRequest,
  EarningdDeduction: EarningdDeduction,
  MasterList: MasterList,
  userManagementReducer: userManagementReducer,
});
