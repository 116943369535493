const initialState = {
   userList:[],
   userCount:0,
   selectedBranch:'ALL',
   selectedPosition:'ALL',
   selectedBranchId:'',
   search:'',
   submit:false,
   page:0,
   rowsPerPage:10,
   loading:false,
   selectedEmployee:[],
   accountList:[],
   pageAccessList:[],
   selectedAccount:[],
   selectedAccountPageAccess:[],
   branchAccessList:[]

}
const dataReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'onChangeUserManagement':
            return {
                ...state,
                ...action.data
            }
        default:
            return state;
    }
}
export default dataReducer;