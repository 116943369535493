import React, { useEffect } from 'react'
// import '../../../../../../App'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import useStyles from '../../../css/css'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import Typography from '@material-ui/core/Typography'
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker
} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import 'date-fns'
import Button from '@material-ui/core/Button'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import { NotificationContainer, NotificationManager } from 'react-notifications'
import { updatingEmployee } from '../../apps/Functions/home_func'
import { data } from 'jquery'

function Personal_Details () {
  const home_reducer = useSelector(state => state.Home)
  const dispatch = useDispatch()
  const dispatch_data = (type, data) => {
    dispatch({
      type: type,
      data: data
    })
  }
  const classes = useStyles()
  const matches = useMediaQuery('(max-width:600px)')
  const [state, setState] = React.useState({
    gender: '',
    civil_status: '',
    birthDate: new Date(),
    emp_age:0
  })
  const onChange = event => {
    setState({
      ...state,
      [event.target.name]: event.target.value
    })
  }
  const handleDateChange = date => {
      console.log(date)
    let current = new Date()
    var month_diff = current - date.getTime()
    var age_dt = new Date(month_diff)
    var year = age_dt.getUTCFullYear()
    var age = Math.abs(year - 1970)
    dispatch_data('personal_update', {
      user_bday: moment(date).format('MM/DD/YYYY')
    })
    dispatch_data('personal_update', { user_age: age })
  }

  const onSubmitEdit = e => {
    e.preventDefault()
    let data = {
      user_fname: home_reducer.personal_details.user_fname,
      user_mname: home_reducer.personal_details.user_mname,
      user_lname: home_reducer.personal_details.user_lname,
      user_sname: home_reducer.personal_details.user_sname,
      user_gender: home_reducer.personal_details.user_gender,
      user_civilstatus: home_reducer.personal_details.user_civilstatus,
      user_age: state.emp_age,
      user_bday: moment(home_reducer.personal_details.user_bday).format(
        'YYYY-MM-DD'
      ),
      birthplace: home_reducer.personal_details.birthplace,

      user_telephone: home_reducer.personal_details.user_telephone,
      user_mobile: home_reducer.personal_details.user_mobile,
      user_gcash: home_reducer.personal_details.user_gcash,
      user_email: home_reducer.personal_details.user_email,

      pres_house_street_subd:
        home_reducer.personal_details.pres_house_street_subd,
      pres_barangay: home_reducer.personal_details.pres_barangay,
      pres_city: home_reducer.personal_details.pres_city,
      pres_province: home_reducer.personal_details.pres_province,
      pres_country: home_reducer.personal_details.pres_country,
      pres_zip: home_reducer.personal_details.pres_zip,
      perm_house_street_subd:
        home_reducer.personal_details.perm_house_street_subd,
      perm_barangay: home_reducer.personal_details.perm_barangay,
      perm_city: home_reducer.personal_details.perm_city,
      perm_province: home_reducer.personal_details.perm_province,
      perm_country: home_reducer.personal_details.perm_country,
      perm_zip: home_reducer.personal_details.perm_zip,

      contact_name: home_reducer.personal_details.contact_name,
      contact_relation: home_reducer.personal_details.contact_relation,
      contact_number: home_reducer.personal_details.contact_number,
      contact_address: home_reducer.personal_details.contact_address,

      sss: home_reducer.personal_details.sss,
      philHealth: home_reducer.personal_details.philHealth,
      pagIbig: home_reducer.personal_details.pagIbig,
      tin: home_reducer.personal_details.tin,
      valucare: home_reducer.personal_details.valucare,

      user_id: home_reducer.personal_details.user_id
    }
    updatingEmployee(data).then(response => {
      if (response.res == 'success') {
        NotificationManager.info(
          'Success',
          'Employee details successfully updated',
          5000,
          true
        )
      } else {
        NotificationManager.warning(
          'Failed',
          'Employee details failed to update.',
          5000,
          true
        )
      }
    })
  }
  useEffect(() => {
    async function getAgebyBday () {
      let current = new Date()
      let bday = new Date(home_reducer.personal_details.user_bday)
      var month_diff = current - bday.getTime()
      var age_dt = new Date(month_diff)
      var year = age_dt.getUTCFullYear()
      var age = Math.abs(year - 1970)
      console.log(age)
      setState(prev => ({ ...prev, emp_age: age }))

    }
    setTimeout(() => {
      getAgebyBday()
    }, 1000)
  }, [])

  return (
    <div className={classes.root} variant='none'>
      <NotificationContainer />
      <form onSubmit={onSubmitEdit}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <Card variant='outlined'>
                  <CardHeader title='Personal Details' />
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <Grid container spacing={2} style={{ marginBottom: 0 }}>
                          <Grid item xs={12} md={12}>
                            <TextField
                            required
                              onChange={text =>
                                dispatch_data('personal_update', {
                                  user_fname: text.target.value
                                })
                              }
                              value={home_reducer.personal_details.user_fname}
                              label='First Name'
                              variant='outlined'
                              size='small'
                              style={{ width: '100%' }}
                            />
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <TextField
                              onChange={text =>
                                dispatch_data('personal_update', {
                                  user_mname: text.target.value
                                })
                              }
                              value={home_reducer.personal_details.user_mname}
                              label='Middle Name'
                              variant='outlined'
                              size='small'
                              style={{ width: '100%' }}
                            />
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <TextField
                              onChange={text =>
                                dispatch_data('personal_update', {
                                  user_lname: text.target.value
                                })
                              }
                            required
                            value={home_reducer.personal_details.user_lname}
                              label='Last Name'
                              variant='outlined'
                              size='small'
                              style={{ width: '100%' }}
                            />
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <TextField
                              onChange={text =>
                                dispatch_data('personal_update', {
                                  user_sname: text.target.value
                                })
                              }
                              value={home_reducer.personal_details.user_sname}
                              label='Suffix Name'
                              variant='outlined'
                              size='small'
                              style={{ width: '100%' }}
                            />
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <FormControl
                              size='small'
                              style={{ width: '100%', marginTop: 3 }}
                              variant='outlined'
                            >
                              <InputLabel id='demo-simple-select-helper-label'>
                                Gender
                              </InputLabel>
                              <Select
                                onChange={text =>
                                  dispatch_data('updateEmployee', {
                                    user_gender: text.target.value
                                  })
                                }
                                labelId='demo-simple-select-helper-label'
                                id='demo-simple-select-helper'
                                name='gender'
                                value={
                                  home_reducer.personal_details.user_gender
                                }
                              >
                                <MenuItem value=''>
                                  {' '}
                                  <em>None</em>{' '}
                                </MenuItem>
                                <MenuItem value='MALE'>Male</MenuItem>
                                <MenuItem value='FEMALE'>Female</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Grid container spacing={2} style={{ marginBottom: 0 }}>
                          <Grid item xs={12} md={12}>
                            <FormControl
                              size='small'
                              style={{ width: '100%', marginTop: 3 }}
                              variant='outlined'
                            >
                              <InputLabel id='demo-simple-select-helper-label'>
                                Civil Status
                              </InputLabel>
                              <Select
                                labelId='demo-simple-select-helper-label'
                                id='demo-simple-select-helper'
                                onChange={text =>
                                  dispatch_data('personal_update', {
                                    user_civilstatus: text.target.value
                                  })
                                }
                                name='civil_status'
                                value={
                                  home_reducer.personal_details.user_civilstatus
                                }
                              >
                                <MenuItem value=''>
                                  {' '}
                                  <em>None</em>{' '}
                                </MenuItem>
                                <MenuItem value='SINGLE'>Single</MenuItem>
                                <MenuItem value='MARRIED'>Married</MenuItem>
                                <MenuItem value='DIVORCED'>Divorced</MenuItem>
                                <MenuItem value='SEPARETED'>Separated</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <TextField
                              onChange={text =>
                                dispatch_data('personal_update', {
                                  user_age: text.target.value
                                })
                              }
                              value={state.emp_age}
                              disabled
                              label='Employee Age'
                              variant='outlined'
                              size='small'
                              style={{ width: '100%' }}
                            />
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <MuiPickersUtilsProvider
                              utils={DateFnsUtils}
                              size='small'
                            >
                              <KeyboardDatePicker
                                id='date-picker-dialog'
                                label='Birth Date'
                                format='MM/dd/yyyy'
                                value={home_reducer.personal_details.user_bday}
                                style={{ width: '100%' }}
                                onChange={handleDateChange}
                                KeyboardButtonProps={{
                                  'aria-label': 'change date'
                                }}
                              />
                            </MuiPickersUtilsProvider>
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <TextField
                              onChange={text =>
                                dispatch_data('personal_update', {
                                  birthplace: text.target.value
                                })
                              }
                              value={home_reducer.personal_details.birthplace}
                              size='small'
                              id='outlined-textarea'
                              label='Birth Place'
                              placeholder='Birth Place'
                              multiline
                              variant='outlined'
                              style={{ width: '100%' }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={12}>
                <Card variant='outlined'>
                  <CardHeader title='Emergency Details' />
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={12}>
                            <TextField
                              onChange={text =>
                                dispatch_data('personal_update', {
                                  contact_name: text.target.value
                                })
                              }
                              value={home_reducer.personal_details.contact_name}
                              label='Contact Name '
                              variant='outlined'
                              size='small'
                              style={{ width: '100%' }}
                            />
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <TextField
                              onChange={text =>
                                dispatch_data('personal_update', {
                                  contact_relation: text.target.value
                                })
                              }
                              value={
                                home_reducer.personal_details.contact_relation
                              }
                              label='Relationship'
                              variant='outlined'
                              size='small'
                              style={{ width: '100%' }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={12}>
                            <TextField
                              onChange={text =>
                                dispatch_data('personal_update', {
                                  contact_number: text.target.value
                                })
                              }
                              value={
                                home_reducer.personal_details.contact_number
                              }
                              type='number'
                              label='Contact Number'
                              variant='outlined'
                              size='small'
                              style={{ width: '100%' }}
                            />
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <TextField
                              onChange={text =>
                                dispatch_data('personal_update', {
                                  contact_address: text.target.value
                                })
                              }
                              value={
                                home_reducer.personal_details.contact_address
                              }
                              multiline
                              label='Contact Address'
                              variant='outlined'
                              size='small'
                              style={{ width: '100%' }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={12}>
                <Card variant='outlined'>
                  <CardHeader title='Employee Details' />
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={12}>
                            <TextField
                              disabled
                              value={home_reducer.personal_details.company_name}
                              label='Assigned Company'
                              variant='outlined'
                              size='small'
                              style={{ width: '100%' }}
                            />
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <TextField
                              disabled
                              value={
                                home_reducer.personal_details.branch_company
                              }
                              label='Assigned Branch'
                              variant='outlined'
                              size='small'
                              style={{ width: '100%' }}
                            />
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <TextField
                              disabled
                              value={home_reducer.personal_details.dept_name}
                              label='Assigned Department'
                              variant='outlined'
                              size='small'
                              style={{ width: '100%' }}
                            />
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <TextField
                              disabled
                              value={
                                home_reducer.personal_details.job_description
                              }
                              label='Job Description'
                              variant='outlined'
                              size='small'
                              style={{ width: '100%' }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={12}>
                            <MuiPickersUtilsProvider
                              utils={DateFnsUtils}
                              size='small'
                              disabled
                            >
                              <KeyboardDatePicker
                                id='date-picker-dialog'
                                name='user_date_hired'
                                value={moment(
                                  home_reducer.personal_details.user_date_hired
                                ).format('MM/DD/yyyy')}
                                label='Date Hired'
                                format='MM-dd-yyyy'
                                style={{ width: '100%' }}
                                // onChange={handleDateHiredChange}
                                KeyboardButtonProps={{
                                  'aria-label': 'change date'
                                }}
                                disabled
                              />
                            </MuiPickersUtilsProvider>
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <TextField
                              disabled
                              value={
                                home_reducer.personal_details.user_jobposition
                              }
                              label='Job Position'
                              variant='outlined'
                              size='small'
                              style={{ width: '100%' }}
                            />
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <TextField
                              disabled
                              value={
                                home_reducer.personal_details.employee_status
                              }
                              label='Employee Status'
                              variant='outlined'
                              size='small'
                              style={{ width: '100%' }}
                            />
                          </Grid>

                          <Grid item xs={12} md={12}>
                            <TextField
                              value={
                                home_reducer.personal_details.account_username
                              }
                              disabled
                              label='Username '
                              variant='outlined'
                              size='small'
                              style={{ width: '100%' }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <Card variant='outlined'>
                  <CardHeader title='Contact Details' />
                  <CardContent>
                    <Grid container spacing={2} style={{ marginBottom: 0 }}>
                      <Grid item xs={12} md={6}>
                        <Grid container spacing={2} style={{ marginBottom: 0 }}>
                          <Grid item xs={12} md={12}>
                            <TextField
                              onChange={text =>
                                dispatch_data('personal_update', {
                                  user_telephone: text.target.value
                                })
                              }
                              value={
                                home_reducer.personal_details.user_telephone
                              }
                              type='number'
                              label='Telephone Number'
                              variant='outlined'
                              size='small'
                              style={{ width: '100%' }}
                            />
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <TextField
                              onChange={text =>
                                dispatch_data('personal_update', {
                                  user_mobile: text.target.value
                                })
                              }
                              value={home_reducer.personal_details.user_mobile}
                              type='number'
                              label='Phone Number'
                              variant='outlined'
                              size='small'
                              style={{ width: '100%' }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Grid container spacing={2} style={{ marginBottom: 0 }}>
                          <Grid item xs={12} md={12}>
                            <TextField
                              onChange={text =>
                                dispatch_data('personal_update', {
                                  user_gcash: text.target.value
                                })
                              }
                              value={home_reducer.personal_details.user_gcash}
                              type='number'
                              label='Gcash Number'
                              variant='outlined'
                              size='small'
                              style={{ width: '100%' }}
                            />
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <TextField
                              onChange={text =>
                                dispatch_data('personal_update', {
                                  user_email: text.target.value
                                })
                              }
                              value={home_reducer.personal_details.user_email}
                              type='email'
                              label='Email'
                              variant='outlined'
                              size='small'
                              style={{ width: '100%' }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={12}>
                <Card variant='outlined'>
                  <CardHeader title='Address Details' />
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={12}>
                            <TextField
                              disabled
                              onChange={text =>
                                dispatch_data('personal_update', {
                                  pres_house_street_subd: text.target.value
                                })
                              }
                              value={
                                home_reducer.personal_details
                                  .pres_house_street_subd
                              }
                              label='Present Street / Subdivision'
                              variant='outlined'
                              size='small'
                              style={{ width: '100%' }}
                            />
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <TextField
                              disabled
                              onChange={text =>
                                dispatch_data('personal_update', {
                                  pres_barangay: text.target.value
                                })
                              }
                              value={
                                home_reducer.personal_details.pres_barangay
                              }
                              label='Present Barangay'
                              variant='outlined'
                              size='small'
                              style={{ width: '100%' }}
                            />
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <TextField
                              disabled
                              onChange={text =>
                                dispatch_data('personal_update', {
                                  pres_city: text.target.value
                                })
                              }
                              value={home_reducer.personal_details.pres_city}
                              label='Present City '
                              variant='outlined'
                              size='small'
                              style={{ width: '100%' }}
                            />
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <TextField
                              disabled
                              onChange={text =>
                                dispatch_data('personal_update', {
                                  pres_province: text.target.value
                                })
                              }
                              value={
                                home_reducer.personal_details.pres_province
                              }
                              label='Present Province '
                              variant='outlined'
                              size='small'
                              style={{ width: '100%' }}
                            />
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <TextField
                              disabled
                              onChange={text =>
                                dispatch_data('personal_update', {
                                  pres_country: text.target.value
                                })
                              }
                              value={home_reducer.personal_details.pres_country}
                              label='Present Country '
                              variant='outlined'
                              size='small'
                              style={{ width: '100%' }}
                            />
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <TextField
                              disabled
                              onChange={text =>
                                dispatch_data('personal_update', {
                                  pres_zip: text.target.value
                                })
                              }
                              value={home_reducer.personal_details.pres_zip}
                              label='Present Zip-code '
                              variant='outlined'
                              size='small'
                              style={{ width: '100%' }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={12}>
                            <TextField
                              disabled
                              onChange={text =>
                                dispatch_data('personal_update', {
                                  perm_house_street_subd: text.target.value
                                })
                              }
                              value={
                                home_reducer.personal_details
                                  .perm_house_street_subd
                              }
                              label='Permanent Street / Subdivision'
                              variant='outlined'
                              size='small'
                              style={{ width: '100%' }}
                            />
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <TextField
                              disabled
                              onChange={text =>
                                dispatch_data('personal_update', {
                                  perm_barangay: text.target.value
                                })
                              }
                              value={
                                home_reducer.personal_details.perm_barangay
                              }
                              label='Permanent Barangay'
                              variant='outlined'
                              size='small'
                              style={{ width: '100%' }}
                            />
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <TextField
                              disabled
                              onChange={text =>
                                dispatch_data('personal_update', {
                                  perm_city: text.target.value
                                })
                              }
                              value={home_reducer.personal_details.perm_city}
                              label='Permanent City '
                              variant='outlined'
                              size='small'
                              style={{ width: '100%' }}
                            />
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <TextField
                              disabled
                              onChange={text =>
                                dispatch_data('personal_update', {
                                  perm_province: text.target.value
                                })
                              }
                              value={
                                home_reducer.personal_details.perm_province
                              }
                              label='Permanent Province '
                              variant='outlined'
                              size='small'
                              style={{ width: '100%' }}
                            />
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <TextField
                              disabled
                              onChange={text =>
                                dispatch_data('personal_update', {
                                  perm_country: text.target.value
                                })
                              }
                              value={home_reducer.personal_details.perm_country}
                              label='Permanent Country '
                              variant='outlined'
                              size='small'
                              style={{ width: '100%' }}
                            />
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <TextField
                              disabled
                              onChange={text =>
                                dispatch_data('personal_update', {
                                  perm_zip: text.target.value
                                })
                              }
                              value={home_reducer.personal_details.perm_zip}
                              label='Permanent Zip-code '
                              variant='outlined'
                              size='small'
                              style={{ width: '100%' }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={12}>
                <Card variant='outlined'>
                  <CardHeader title='Government ID Details' />
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={12}>
                            <TextField
                              disabled
                              onChange={text =>
                                dispatch_data('personal_update', {
                                  sss: text.target.value
                                })
                              }
                              value={home_reducer.personal_details.sss}
                              label='Social Security System No.'
                              variant='outlined'
                              size='small'
                              style={{ width: '100%' }}
                            />
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <TextField
                              disabled
                              onChange={text =>
                                dispatch_data('personal_update', {
                                  philHealth: text.target.value
                                })
                              }
                              value={home_reducer.personal_details.philHealth}
                              label='PhilHealth No.'
                              variant='outlined'
                              size='small'
                              style={{ width: '100%' }}
                            />
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <TextField
                              disabled
                              onChange={text =>
                                dispatch_data('updateEmployee', {
                                  valucare: text.target.value
                                })
                              }
                              value={home_reducer.personal_details.valucare}
                              label='VALUCARE No.'
                              variant='outlined'
                              size='small'
                              style={{ width: '100%' }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={12}>
                            <TextField
                              disabled
                              onChange={text =>
                                dispatch_data('personal_update', {
                                  pagIbig: text.target.value
                                })
                              }
                              value={home_reducer.personal_details.pagIbig}
                              label='Pag-IBIG No.'
                              variant='outlined'
                              size='small'
                              style={{ width: '100%' }}
                            />
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <TextField
                              disabled
                              onChange={text =>
                                dispatch_data('personal_update', {
                                  tin: text.target.value
                                })
                              }
                              value={home_reducer.personal_details.tin}
                              label='Tin No.'
                              variant='outlined'
                              size='small'
                              style={{ width: '100%' }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <div
          style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 10 }}
        >
          <Button
            type='submit'
            variant='contained'
            color='primary'
            disableElevation
            style={{ backgroundColor: '#009197', color: 'white' }}
          >
            {' '}
            Update
          </Button>
        </div>
      </form>
    </div>
  )
}
export default Personal_Details
