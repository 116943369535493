// import Employeelist from "../components/apps/pocketHr/employeelist/index";
// import Masterlist from "../components/apps/pocketHr/masterlist/index";
// import Monitoring from "../components/apps/pocketHr/Monitoring/index";
// import AdminControl from "./apps/app_settings/admin_control";
// import LandingPage from "./apps/Index/index";
// import Mail from "./apps/mail/mail";
// import Inventory from "./apps/pocketHr/equipments/inventory/inventory";
// import Inventory_Item from "./apps/pocketHr/equipments/inventory_item/inventory_item";
// import ManualAbsent from "./apps/pocketHr/ManualFiling/Absent/absent";
// import ManualLeave from "./apps/pocketHr/ManualFiling/Leave/leave";
// import Dashboard from "./apps/pocketHr/Monitoring/dashboard/index";
// import Logs from "./apps/pocketHr/Monitoring/logs/logs";
// import MemoMonitoringIndex from "./apps/pocketHr/Monitoring/monitoring_memo/index";
// import govDues from "./apps/pocketHr/Payroll/Government Dues/governmentDues";
// import Holidays from "./apps/pocketHr/Payroll/Holidays/index";
// import AdditionalAdjustment from "./apps/pocketHr/Payroll/PayrollAdjustment/Additional/additional";
// import DeductionAdjustment from "./apps/pocketHr/Payroll/PayrollAdjustment/Deduction/deduction";
// import PayrollDetails from "./apps/pocketHr/Payroll/PayrollDetails/payrollDetails";
// import PayrollEmployee from "./apps/pocketHr/Payroll/PayrollEmployeeList/index";
// import PayrollGroup from "./apps/pocketHr/Payroll/PayrollGroup/index";
// import HolidaySettings from "./apps/pocketHr/Payroll/PayrollSettings/holiday_settings";
// import Late from "./apps/pocketHr/Payroll/PayrollSettings/late";
// import Overwrite from "./apps/pocketHr/Payroll/PayrollSettings/overwrite";
// import PayrollHierarchy from "./apps/pocketHr/Payroll/PayrollSettings/payroll_hierarchy";
// import Deductions from "./apps/pocketHr/Payroll/Retro/Deductions/deduction";
// import Earnings from "./apps/pocketHr/Payroll/Retro/Earnings/earning";
// import SalarySettings from "./apps/pocketHr/Payroll/SalarySettings/salarysettings";
// import EmployeeThirteenMonth from "./apps/pocketHr/Payroll/ThirteenMonthPay/employee_thirteenMonth";
// import ThirteenthMonthPay from "./apps/pocketHr/Payroll/ThirteenMonthPay/index";
// import ThirteenMonthPayDetails from "./apps/pocketHr/Payroll/ThirteenMonthPay/thirteenMonth_details";
// import Accomplishments from "./apps/pocketHr/Timesheet/Accomplishments/Accomplishments";
// import LeaveSheet from "./apps/pocketHr/Timesheet/Employee Leave Sheet/Employee_Leave_Sheet";
// import OBSheet from "./apps/pocketHr/Timesheet/Employee OB Sheet/Employee_OB_Sheet";
// import OTSheet from "./apps/pocketHr/Timesheet/Employee OT Sheet/Employee_OT_Sheet";
// import DTR from "./apps/pocketHr/Timesheet/Employee Time Sheet/DTR";
// import EmployeeSheet from "./apps/pocketHr/Timesheet/Employee Time Sheet/Employee_Time_Sheet";
// import UndertimeSheet from "./apps/pocketHr/Timesheet/Employee Undertime Sheet/Employee_Undertime_Sheet";
// import MonitoringSheet from "./apps/pocketHr/Timesheet/Monitoring Sheet/Monitoring_Sheet";
// import PendingRequest from "./apps/pocketHr/Timesheet/Pending Request/PendingRequest";
// import UploadDoubleBook from "./apps/pocketHr/Timesheet/Upload Timesheet/uploadDoubleBook";
// import UploadJobOrder from "./apps/pocketHr/Timesheet/Upload Timesheet/uploadJobOrder";
// import UploadTimeSheet from "./apps/pocketHr/Timesheet/Upload Timesheet/uploadTimesheet";
// import UploadTrips from "./apps/pocketHr/Timesheet/Upload Timesheet/uploadTrips";

// import BudgetPortal from "./apps/pocketHr/vehicles/Cheque/cheque";
// import VehiclesDash from "./apps/pocketHr/vehicles/Dashboard/dashboard";
// import FuelPortal from "./apps/pocketHr/vehicles/Portal/portal";
// import Vehicles from "./apps/pocketHr/vehicles/Vehicle/vehicle";
// import AccountPriviledge from "./apps/system_settings/account_priviledge/account_priviledge";
// import AccountSettings from "./apps/system_settings/account_settings/accountsettings";
// import BranchSettings from "./apps/system_settings/branch_settings/branch_settings";
// import CompanySettings from "./apps/system_settings/company_settings/company_settings";
// import DepartmentSettings from "./apps/system_settings/department_settings/department_settings";
// import MobilePriviledge from "./apps/system_settings/mobile_priviledge/mobile_priviledge";
// import NavigationSettings from "./apps/system_settings/navigation_settings/navigationsettings";
// import PositionSettings from "./apps/system_settings/position_settings/position_settings";
// import SalarySchedule from "./apps/system_settings/salary_schedule/salary_schedule";
// import ScheduleSettingsFieldman from "./apps/system_settings/schedule_fieldman/schedule_fieldman";
// import ScheduleSettings from "./apps/system_settings/schedule_settings/schedule_settings";
// import ScheduleFieldman from "./apps/pocketHr/Scheduling/index";
// import DocSheet from "./apps/pocketHr/Timesheet/Employee_Docs_Sheet/Employee_Docs_Sheet";
// import WFHSheet from "./apps/pocketHr/Timesheet/WFH/WFH";
// import PayrollRule from "./apps/pocketHr/Payroll/PayrollRule/index";
// import EmpAppeal from "./apps/pocketHr/Timesheet/Employee Appeal/employee_appeal";
// import ReqHierarchySettings from "./apps/system_settings/req_hierarchy_settings/index";
// import Trips from "./apps/pocketHr/trips/index";
// import Memo from "./apps/pocketHr/Memorandum/index";
// import RequestForPayment from "./apps/pocketHr/Finance/RequestForPayment/index";
// import MemoAbsent from "./apps/pocketHr/Memorandum/absent/absent";
// import QuestionBank from "./apps/system_settings/question_bank/question_bank";
// import ApplicantList from "./apps/pocketHr/applicant/index";
// import EmployeeGrading from "./apps/pocketHr/Grading/grading";
// import GradingCriteria from "./apps/system_settings/grading_criteria/grading_criteria";
// import PayrollReports from "./apps/pocketHr/Reports/PayrollReport/indexPayroll";
// import PayrollRequest from "./apps/pocketHr/Payroll/PayrollRequest/payrollRequest";
// import PayrollEmployeeList from "./apps/pocketHr/Payroll/PayrollRequest/payrollEmployeeList";
// import IndexDeductionLoans from "./apps/pocketHr/Payroll/EarningDeduction/Deductions/indexDeductions";
// import IndexEarning from "./apps/pocketHr/Payroll/EarningDeduction/Earnings/indexEarning";
// import IndexAllBranch from "./apps/pocketHr/Reports/PayrollReport/AllBranchReports/indexAllBranch";
// import IndexTabs from "./apps/pocketHr/Reports/PayrollReport/indexTabs";
// import IndexTabPayroll from "./apps/pocketHr/Payroll/PayrollGroup/indexTab";
// import IndexMasterListV2 from "./apps/pocketHr/MaterListV2/indexMasterList";
// import Visitor from "./apps/pocketHr/Visitor/visitor";
// import IndexPayrollBatch from "./apps/pocketHr/Payroll/PayrollSettings/PayrollBatchV2/indexPayrollBatch";
// import PayrollRuleV2 from "./apps/pocketHr/Payroll/PayrollRuleV2/index";
// import { exact } from "prop-types";
// import { Menu } from "@material-ui/core";
// import IndexAccess from "./apps/pocketHr/userAccess/indexAccess";
// import IndexAttendanceMonitoring from "./apps/pocketHr/AttendanceMonitoring/indexAttendanceMonitoring";
// import EmployeeStraightDuty from "./apps/pocketHr/Timesheet/Employee Straight Duty/EmployeeStraightDuty";
// import AdvanceDuty from "./apps/pocketHr/Timesheet/Advance Duty/AdvanceDuty";
// import DailyScheduling from "./apps/system_settings/dailySchecduling/dailyScheduling";
// import Grading from './apps/pocketHr/Grading/index'
// function routes() {

const routes = [
  {
    path: "/",
    exact: true,

    page: "Default",
  },
  {
    path: "/mail/",
    exact: true,

    page: "Mail",
  },
  {
    path: "/masterlist/",
    exact: true,

    page: "Master List",
  },
  {
    path: "/employeelist/",
    exact: true,

    page: "Employee List",
  },
  {
    path: "/leavesheet/",
    exact: true,

    page: "Leave Sheet",
  },
  {
    path: "/thirteenthMonthPay/",
    exact: true,

    page: "Thirteenth Month Pay",
  },
  {
    path: "/manualAbsent/",
    exact: true,

    page: "Manual Absent",
  },
  // {
  //     path: '/memoMonitoringIndex/',
  //     exact: true,
  //     component: MemoMonitoringIndex,
  //     page: 'Memo Monitoring'
  // },

  {
    path: "/vehicledashboard/",
    exact: true,

    page: "Vehicle Dashboard",
  },
  {
    path: "/pending_request/",
    exact: true,

    page: "Pending Requests",
  },
  {
    path: "/fuelportal/",
    exact: true,

    page: "Fuel Portal",
  },
  {
    path: "/budgetportal/",
    exact: true,

    page: "Budget Portal",
  },
  {
    path: "/monitoringsheet/",
    exact: true,

    page: "Monitoring Sheet",
  },
  {
    path: "/payrollRules/",
    exact: true,

    page: "Payroll Rules",
  },
  {
    path: "/trips/",
    exact: true,

    page: "Trips",
  },
  {
    path: "/requestForPayment/",
    exact: true,

    page: "Request For Payment",
  },
  {
    path: "/memoAbsent/",
    exact: true,

    page: "Memo Absent",
  },
  {
    path: "/payrollReport/",
    exact: true,

    page: "Payroll Reports",
  },

  {
    path: "/obsheet/",
    exact: true,

    page: "OB Sheet",
  },
  {
    path: "/otsheet/",
    exact: true,

    page: "Overtime Sheet",
  },
  {
    path: "/undertimesheet/",
    exact: true,

    page: "Undertime Sheet",
  },
  {
    path: "/employeesheet/",
    exact: true,

    page: "Employee Sheet",
  },
  {
    path: "/dtr/",
    exact: true,

    page: "DTR",
  },
  {
    path: "/monitoring/",
    exact: true,

    page: "Attendance Monitoring",
  },
  {
    path: "/payrollGroup/",
    exact: true,

    page: "Payroll Group",
  },
  {
    path: "/payrollEmployee/",
    exact: true,

    page: "Payroll Employee",
  },
  {
    path: "/payrollDetails/",
    exact: true,

    page: "Payroll Details",
  },
  {
    path: "/holidays/",
    exact: true,

    page: "Holidays",
  },
  {
    path: "/retroearnings/",
    exact: true,

    page: "Retro Earnings",
  },
  {
    path: "/retrodeductions/",
    exact: true,

    page: "Retro Deductions",
  },
  {
    path: "/governmentdues/",
    exact: true,

    page: "Government Dues",
  },
  {
    path: "/logs/",
    exact: true,

    page: "App Logs",
  },
  {
    path: "/attendashboard/",
    exact: true,

    page: "Attendance Dashboard",
  },
  {
    path: "/companysettings/",
    exact: true,

    page: "Company Settings",
  },
  {
    path: "/schedulesettings/",
    exact: true,

    page: "Schedule Settings",
  },
  {
    path: "/latesettings/",
    exact: true,

    page: "Late Settings",
  },
  {
    path: "/overwrite/",
    exact: true,

    page: "Overwrite",
  },
  {
    path: "/additionalAdjustment/",
    exact: true,

    page: "Additional Adjustment",
  },
  {
    path: "/deductionAdjustment/",
    exact: true,

    page: "Deduction Adjustment",
  },
  {
    path: "/payrollHierarchy/",
    exact: true,

    page: "Payroll Hierarchy",
  },
  {
    path: "/holidaySettings/",
    exact: true,

    page: "Holiday And Wages Rate",
  },
  {
    path: "/admincontrol/",
    exact: true,

    page: "Admin Control",
  },
  {
    path: "/salarysettings/",
    exact: true,

    page: "Salary Settings",
  },
  {
    path: "/accountpriviledge/",
    exact: true,

    page: "Account Priviledge",
  },
  {
    path: "/navigationsettings/",
    exact: true,

    page: "Navigation Settings",
  },
  {
    path: "/branchsettings/",
    exact: true,

    page: "Branch Settings",
  },
  {
    path: "/departmentsettings/",
    exact: true,

    page: "Department Settings",
  },
  {
    path: "/positionsettings/",
    exact: true,

    page: "Position Settings",
  },
  {
    path: "/accountsettings/",
    exact: true,

    page: "Account Settings",
  },
  {
    path: "/uploadTimeSheet/",
    exact: true,

    page: "Upload TimeSheet",
  },
  {
    path: "/uploadDoubleBook/",
    exact: true,
    page: "Upload DoubleBook",
  },
  {
    path: "/uploadJobOrder/",
    exact: true,
    page: "Upload JobOrder",
  },
  {
    path: "/uploadTrips/",
    exact: true,
    page: "Upload Trips",
  },
  {
    path: "/mobilepriv/",
    exact: true,
    page: "Mobile Priviledge",
  },
  {
    path: "/accomplishments/",
    exact: true,
    page: "Accomplishments",
  },
  {
    path: "/scheduleSettingsFieldman/",
    exact: true,
    page: "Time Table Settings",
  },
  {
    path: "/manualAbsent/",
    exact: true,
    page: "Manual Absent",
  },
  {
    path: "/manualLeave/",
    exact: true,
    page: "Manual Leave",
  },
  {
    path: "/ThirteenMonthPayDetails/",
    exact: true,
    page: "Thirteen Month Pay Details",
  },
  {
    path: "/EmployeeThirteenMonth/",
    exact: true,
    page: "Employee Thirteen Month",
  },
  {
    path: "/tripRates/",
    exact: true,
    page: "Trip Rates",
  },
  {
    path: "/inventory/",
    exact: true,
    page: "Inventory",
  },
  {
    path: "/inventory_item/",
    exact: true,
    page: "Inventory Item",
  },
  // {
  //     path: '/memoMonitoringIndex/',
  //     exact: true,
  //     component: MemoMonitoringIndex,
  //     page: 'Memo Monitoring'
  // },
  {
    path: "/vehicles/",
    exact: true,
    page: "Vehicles",
  },
  {
    path: "/scheduling_field/",
    exact: true,
    page: "Scheduling PocketHr",
  },
  {
    path: "/document_sheet/",
    exact: true,
    page: "Document Sheet",
  },
  {
    path: "/wfh_sheet/",
    exact: true,
    page: "Work From Home",
  },
  {
    path: "/appeal/",
    exact: true,
    page: "Employee Appeal",
  },
  {
    path: "/req_hierarchy_settings/",
    exact: true,
    page: "Request Hierarchy Settings",
  },
  {
    path: "/trips/",
    exact: true,
    page: "Trips",
  },
  {
    path: "/question_bank/",
    exact: true,
    page: "Question Bank",
  },
  // {
  //     path: '/employeeGrading/',
  //     exact: true,
  //     component: Grading,
  //     page: 'Employee Grading'
  // },
  {
    path: "/applicant-list/",
    exact: true,
    page: "Applicant List",
  },
  {
    path: "/employee_grading/",
    exact: true,
    page: "Employee Grading",
  },
  {
    path: "/grading_criteria/",
    exact: true,
    page: "Grading Criteria",
  },
  {
    path: "/payrollRequest/",
    exact: true,
    page: "Payroll Requests",
  },
  {
    path: "/payrollEmployeeList/",
    exact: true,
    page: "Payroll Employee List",
  },
  {
    path: "/memo/",
    exact: true,
    page: "Memo Monitoring",
  },
  {
    path: "/visitortable/",
    exact: true,
    page: "Visitor Table",
  },
  {
    path: "/payrollBatch/",
    exact: true,
    page: "Payroll Batch",
  },
  {
    path: "/earningsV2/",
    exact: true,
    page: "EarningsV2",
  },
  {
    path: "/deductionsV2/",
    exact: true,
    page: "DeductionsV2",
  },
  {
    path: "/payrollRuleV2/",
    exact: true,
    page: "Payroll Rule V2",
  },
  {
    path: "/menu/",
    exact: true,
    page: "Main Menu",
  },
  {
    path: "/accessPriviledgesV2/",
    exact: true,
    page: "Access Priviledges V2",
  },
  {
    path: "/attendanceMonitoringV2/",
    exact: true,
    page: "Attendance Monitoring Version2",
  },
  {
    path: "/changeDuty/",
    exact: true,
    page: "Change Duty",
  },
  ,
  {
    path: "/advanceDuty/",
    exact: true,
    page: "Advance Duty",
  },
  {
    path: "/request_sheet/",
    exact: true,
    page: "Request Sheet",
  },
];
// return routes
// }

export default routes;
