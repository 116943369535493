import axios from "axios";
// import swal from "sweetalert/dist/sweetalert.min.js";
// let serverurl = "http://api.pacificweb.com.ph/";
// let serverurl = "http://localhost/api/";
// let serverurl ="http://192.168.0.9/backend/api/";
// let serverurl ="http://192.168.0.10/backend/api.workflow/";
// let serverurl = "http://beta.api.pacificweb.com.ph/";
// let serverurl ="http://192.168.60.118/api/";
let serverurl = "https://api.workflow.gzonetechph.com/";
// let serverurl = "https://beta.workflow.gzonetechph.com/";
let key = "?key=PocketHR@20190208&type=web";
// let key2 = "?key=12345ABCFleet";
const CancelToken = axios.CancelToken;
let source = CancelToken.source();
export function getData(method, param) {
  return new Promise((resolve, reject) => {
    axios
      .post(
        serverurl + method + key,
        {
          param,
        },
        {
          cancelToken: source.token,
        }
      )
      .then((res) => {
        resolve(res.data);
      });
  });
}
export function cancelRequest() {
  source.cancel("Operation canceled by the user.");
  source = CancelToken.source();
}
