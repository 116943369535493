import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect } from "react";
import "react-notifications/lib/notifications.css";
import { HashRouter as Router, Route } from "react-router-dom";
import AppRouter from "../src/components/router";
import "./App.css";
import { onMessageListener, getTokenVal } from "./components/firebase";
import { getData } from "./components/api/api";
import { useSelector, useDispatch } from "react-redux";
import { usePageVisibility } from "./components/utilities/visibility";

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    background: "#2f3640",
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

function App() {
  let activateBackup = null;
  let pageDetector = 0;
  const isVisible = usePageVisibility();
  if (isVisible) {
    document.title = "Active";
    pageDetector = "Active";
    if (activateBackup === false) {
      // so it will not triggered on initial render
      activateBackup = true;
    }
  } else {
    document.title = "Inactive";
    pageDetector = "Inactive";
    activateBackup = false;
  }

  const dispatch = useDispatch();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [state, setState] = React.useState({
    loginData: {
      app_id: 0,
      user_id: localStorage.getItem("u"),
    },
  });
  const [show, setShow] = React.useState(false);
  const [notification, setNotification] = React.useState({
    title: "",
    body: "",
  });
  const refreshRequest = useSelector((state) => state.Home.refreshRequest);
  const refreshRequestCount = useSelector(
    (state) => state.Home.refreshRequestCount
  );

  const [isTokenFound, setTokenFound] = React.useState(false);
  const [tokenValue, setTokenValue] = React.useState("");
  getTokenVal(setTokenFound, setTokenValue);
  console.log(tokenValue);

  onMessageListener()
    .then((payload) => {
      // getRequestCount()
      dispatch({
        type: "onChangeHomeReducer",
        data: {
          refreshRequest: !refreshRequest,
        },
      });
      getPayrollRequestsCount();
      setShow(true);
      setNotification({
        title: payload.notification.title,
        body: payload.notification.body,
      });
    })
    .catch((err) => console.log("failed: ", err));

  const getUrlVars = () => {
    var vars = {};
    var parts = window.location.href.replace(
      /[?&]+([^=&]+)=([^&]*)/gi,
      function (m, key, value) {
        vars[key] = value;
      }
    );

    return vars;
  };

  const getUrlParam = (parameter, defaultvalue) => {
    var urlparameter = defaultvalue;
    if (window.location.href.indexOf(parameter) > -1) {
      urlparameter = getUrlVars()[parameter];
    }

    return urlparameter;
  };
  useEffect(async () => {
    localStorage.setItem("login_state", 0);
    if (typeof localStorage.getItem("u") === "object") {
      if (typeof getUrlVars()["u"] !== "undefined") {
        localStorage.setItem("u", getUrlVars()["u"]);
      } else {
        localStorage.setItem("cookies", 1);
        window.location.replace(
          // "https://login.pockethr.gzonetechph.com/?goto=" +
          "https://login.pockethr.gzonetechph.com/?goto=" +
          window.location +
          "&app=" +
          state.loginData.app_id
        );
      }
    }
  }, []);
  useEffect(() => {
    updateToken();
  }, [tokenValue]);

  React.useEffect(() => {
    getPayrollRequestsCount();
  }, [activateBackup, refreshRequestCount]);

  const updateToken = () => {
    let data = {
      user_id: localStorage.getItem("u"),
      tokenValue: tokenValue,
    };
    getData("humanResourceV2/updateToken", data).then((res) => { });
  };
  const getPayrollRequestsCount = () => {
    let data = {
      user_id: localStorage.getItem("u"),
      page: 1,
      limit: 30000,
    };
    getData("humanResourceV2/getPayrollRequestsCount", data).then((res) => {
      dispatch({
        type: "onChangeHomeReducer",
        data: {
          refreshRequest: !refreshRequest,
          payrollRequestCount: res.res,
        },
      });
    });
  };

  return (
    <div className="App">
      <Router>
        {/* <Route exact path='/' component={Login}></Route> */}
        <Route path="/" component={AppRouter}></Route>
      </Router>
    </div>
  );
}

export default App;
