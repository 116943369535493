const initialState = {
    inventory: [],
    items: [],
    search_inventory: [],
    search_item: []
}
const dataReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'inventory':
            return {
                ...state,
                inventory: action.data,
                search_inventory: action.data,
            }
        case 'items':
            return {
                ...state,
                items: action.data,
                search_item: action.data,
            }
        case 'added_inventory':
            return {
                ...state,
                inventory: state.inventory.concat(action.data),
                search_inventory: state.search_inventory.concat(action.data),
            }
        case 'search_inventory':
            let InventorySearch = state.inventory.filter(
                (files) => {
                    return files.inventory_type.toLowerCase().indexOf(
                        action.data.toLocaleLowerCase()) !== -1 || files.inventory_name.toLowerCase().indexOf(
                            action.data.toLocaleLowerCase()) !== -1
                }
            )
            return {
                ...state,
                search_inventory: InventorySearch
            }
        case 'search_item':
            let ItemSearch = state.items.filter(
                (files) => {

                    return files.assign_name !== null && files.assign_name.toLowerCase().indexOf(
                        action.data.toLocaleLowerCase()) !== -1 || files.added_name !== null && files.added_name.toLowerCase().indexOf(
                            action.data.toLocaleLowerCase()) !== -1
                }
            )
            return {
                ...state,
                search_item: ItemSearch
            }
        default:
            return state;
    }

}
export default dataReducer; 